import React, {
  useMemo,
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";

export const EditorLockContext = createContext();

export default function EditorLockProvider(props) {
  const { children } = props;

  const [editorLocks, setEditorLocks] = useState({});

  const lock = useCallback((key) => {
    setEditorLocks((old) => ({
      ...old,
      [key]: true,
    }));
  }, []);

  const unlock = useCallback((key) => {
    setEditorLocks((old) => {
      const next = { ...old };
      delete next[key];
      return next;
    });
  }, []);

  const editorLockingIds = useMemo(() => Object.keys(editorLocks), [
    editorLocks,
  ]);

  const value = useMemo(
    () => ({ lock, unlock, editorLocks, editorLockingIds }),
    [lock, unlock, editorLocks, editorLockingIds]
  );

  return (
    <EditorLockContext.Provider value={value}>
      {children}
    </EditorLockContext.Provider>
  );
}

export function useEditorLock(key, shouldBeLocked) {
  const { lock, unlock, editorLocks } = useContext(EditorLockContext);
  const isLocked = editorLocks[key];

  useEffect(() => {
    if (!key) return;
    if (!!isLocked !== !!shouldBeLocked) {
      if (shouldBeLocked) lock(key);
      else unlock(key);
    }
  }, [isLocked, key, lock, unlock, shouldBeLocked]);

  useEffect(() => {
    return () => key && unlock(key);
  }, [key, unlock]);
}
