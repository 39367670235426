export function getSearchUrl(workspaceId, queryString = "") {
  if (!workspaceId) throw new Error("No workspace id given to getSearchUrl");
  return (
    "/" +
    [workspaceId, "search", encodeURIComponent(queryString)]
      .filter((v) => v)
      .join("/")
  );
}

export function getNodeUrl(workspaceId, nodeId) {
  return ["", workspaceId, "files", nodeId].join("/");
}
