import { DataId } from "./deltas";

export enum NodeType {
  File,
  Relationship,
  Flashcard,
}

export interface BaseNode {
  type: NodeType;
  tags: string[];
  // id: NodeId;
  // isGenerated?: boolean;
}

export interface RelationshipNode extends BaseNode {
  parent: DataId;
  child: DataId;
  type: NodeType.Relationship;
}

export interface FileNode extends BaseNode {
  name: string;
  type: NodeType.File;
}

export interface FlashcardNode extends BaseNode {
  frontContent: DataId;
  backContent: DataId;
  type: NodeType.Flashcard;
}

export type Node = RelationshipNode | FileNode | FlashcardNode;

// export function blankNode(): Node {
//   return {
//     name: "",
//     type: NodeType.Folder,
//     tags: [],
//   };
// }
