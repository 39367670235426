import {
  Box,
  Divider,
  Flex,
  HStack,
  Progress,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { format as formatTimeAgo } from "timeago.js";
import {
  fullDayNames,
  fullMonthNames,
  getDayOfYear,
  isLeapYear,
} from "../utils/utils";

export default function CalendarHeader(props) {
  const { date = new Date() } = props;

  const now = new Date();

  const isToday =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();

  const timeAgo = isToday ? "today" : formatTimeAgo(date);

  return (
    <Flex {...props}>
      <CalendarDay date={date} />

      <Box flexGrow="1">
        <HStack mb="0.75rem" height="2rem">
          <span>{fullDayNames[date.getDay()]}</span>
          <Divider orientation="vertical" />
          <span>{timeAgo}</span>
        </HStack>

        <Progress
          max={isLeapYear(date) ? 366 : 365}
          value={getDayOfYear(date)}
          mb="0.75rem"
          maxWidth="12rem"
          borderRadius="1000px"
        />

        <Flex mb="0.75rem">Day {getDayOfYear(date)} of the year </Flex>
      </Box>
    </Flex>
  );
}

function CalendarDay(props) {
  const { date } = props;
  const { colorMode } = useColorMode();

  return (
    <Flex flexDirection="column" mr="1.25rem">
      <Flex
        flexDirection="column"
        width="6rem"
        borderRadius="0.5rem"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.3)"
        overflow="hidden"
        backgroundColor="rgba(255, 255, 255, 0.1)"
      >
        <Flex
          justifyContent="center"
          alignSelf="stretch"
          textAlign="center"
          background={`rgba(200, 0, 0, ${
            { dark: "0.5", light: "1" }[colorMode]
          })`}
          color="white"
          // py="0.1rem"
        >
          {fullMonthNames[date.getMonth()]}
        </Flex>
        <Flex
          fontSize="2.5rem"
          fontWeight="600"
          alignItems="center"
          justifyContent="center"
        >
          {date.getDate()}
        </Flex>
      </Flex>
    </Flex>
  );
}
