import { useToast } from "@chakra-ui/react";
import { useCallback, useContext, useMemo } from "react";
import { ActiveWorkspaceContext } from "../providers/ActiveWorkspaceProvider";
import {
  DragAndDropStateContext,
  useDraggable,
  useDropTarget,
} from "../providers/DragAndDropStateProvider";

export function useNodeDrag(props) {
  const { ref, node } = props;
  const { moveNode } = useContext(ActiveWorkspaceContext); // TODO: optimize?
  const { dragFrom, dragTo } = useContext(DragAndDropStateContext);
  const showToast = useToast();

  const dragItem = useMemo(
    () => ({
      type: "node",
      node,
    }),
    [node]
  );

  const validateDrag = useCallback(
    // TODO: validate different types for different folder types? share with moveNode checks?
    (from) => {
      return (
        from.type === "node" &&
        (node.type === "folder" || node.type === "activity") &&
        from.node.id !== node.id && // not itself
        from.node.parent !== node.id // not moved to own parent
      );
    },
    [node.type, node.id]
  );

  const onDragAndDrop = useCallback(
    async (from, to) => {
      await moveNode(from.node.id, to.node.id);
      showToast({
        position: "bottom-left",
        title: `Moved "${from.node.name}" to "${to.node.name}"`,
        status: "success",
        isClosable: true,
      });
    },
    [moveNode, showToast]
  );

  const draggableProps = useDraggable({ dragItem });

  const dragTargetProps = useDropTarget({
    ref,
    dragItem,
    onDragAndDrop,
    validateDrag,
  });

  const isTargeted = dragItem === dragTo && dragFrom !== dragTo;
  const isDragged = dragFrom === dragItem;

  return useMemo(
    () => ({ draggableProps, dragTargetProps, isTargeted, isDragged }),
    [draggableProps, dragTargetProps, isTargeted, isDragged]
  );
}
