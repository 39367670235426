import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useRef } from "react";
import { FaCaretDown } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import { useWindowSize } from "react-use";
import { filesRef } from "../firebase";
import { useNodeDrag } from "../hooks/useNodeDrag";
import {
  ActiveWorkspaceContext,
  NodeListingContext,
} from "../providers/ActiveWorkspaceProvider";
import { Crumb, CrumbSeparator } from "../ui/nav/Crumb";
import { getNodeUrl } from "../utils/urlGetters";
import { findPath, getNodeName, randomId } from "../utils/utils";
import { useUploadedFileUrl } from "./editors/UploadedFileEditor";

export function PathCrumbs(props) {
  const { interactive, targetNodeId, ...rest } = props;
  const { fileTree } = useContext(ActiveWorkspaceContext);
  const { workspaceId, nodeId } = useParams();
  const history = useHistory();

  const targetNode = fileTree[targetNodeId || nodeId];

  const { width } = useWindowSize();

  if (!targetNode) return <Text mr="0.75rem">File not found</Text>;

  const path = findPath(fileTree, targetNode.id);

  // TODO: move to menu
  const showMiniCrumb = width < 800;
  if (showMiniCrumb) {
    return (
      <>
        {targetNode?.id !== "$ROOT" && (
          <IconButton
            className="fade-in"
            variant="outline"
            mr="0.5rem"
            icon={<ArrowBackIcon />}
            title="Parent folder"
            as={Link}
            to={`/${workspaceId}/files/${targetNode?.parent}`}
            // height="auto"
            // p="0.55rem"
          />
        )}
        <PathCrumb
          className="fade-in"
          mr="0.5rem"
          node={targetNode}
          isLast
          interactive={interactive}
        />
      </>
    );
  }

  return (
    <Flex
      className="fade-in"
      whiteSpace="nowrap"
      overflow="hidden"
      alignItems="center"
      ml="0.5rem"
      {...rest}
    >
      {path.map((node, i) => {
        const isActive = node.id === targetNode.id;
        const isClickable = interactive && !isActive;

        return (
          <React.Fragment key={i}>
            {!!i && <CrumbSeparator className="slide-from-left" />}
            <PathCrumb
              className="slide-from-left"
              node={node}
              isLast={i === path.length - 1}
              interactive={interactive}
              onClick={(event) => {
                if (!isClickable) return;
                event.preventDefault();
                history.push(getNodeUrl(workspaceId, node.id));
              }}
            />
          </React.Fragment>
        );
      })}
    </Flex>
  );
}

const PathCrumb = React.forwardRef((props, passedRef) => {
  const { isLast, node, crumbRef, ...rest } = props;

  const localRef = useRef();
  const ref = passedRef || localRef;

  const {
    // draggableProps,
    // isDragged,
    dragTargetProps,
    isTargeted,
  } = useNodeDrag({ node, ref });

  const { activeWorkspace } = useContext(ActiveWorkspaceContext);

  const name = node.id === "$ROOT" ? activeWorkspace.name : getNodeName(node);

  const sharedProps = {
    ref,
    title: name,
    isTargeted,
    isLast,
    ...rest,
    ...dragTargetProps,
  };

  // if (isLast && node.id !== "$ROOT") {
  return (
    <Menu isLazy placement="auto">
      <MenuButton
        as={Crumb}
        rightIcon={isLast && <FaCaretDown />}
        {...sharedProps}
      >
        {name}
      </MenuButton>

      <NodeMenuList canSelectAllChildren node={node} zIndex={1000} />
    </Menu>
  );
  // }
});

// TODO: move
export function NodeMenuList(props) {
  const { node, canSelectAllChildren, ...rest } = props;

  const {
    setSelectedNodeIds,
    setBrowseModeOverride,
    toggleNodeSelected,
    setNode,
  } = useContext(ActiveWorkspaceContext);

  const nodeListing = useContext(NodeListingContext);

  return (
    <MenuList {...rest}>
      {!!nodeListing && (
        <MenuItem onClick={() => toggleNodeSelected(node.id)}>Select</MenuItem>
      )}

      {node?.type === "uploaded_file" && <DownloadMenuItem node={node} />}

      {node?.id !== "$ROOT" && (
        <>
          <MenuItem
            onClick={() =>
              setBrowseModeOverride({ type: "rename", ids: [node.id] })
            }
          >
            Rename
          </MenuItem>
          {nodeListing && ["note", "timed_routine"].includes(node.type) && (
            <MenuItem
              onClick={async () => {
                const id = randomId();
                const snapshot = await filesRef.doc(node.id).get();
                filesRef.doc(id).set(snapshot.data());

                await setNode({
                  ...node,
                  id,
                  name: getNodeName(node) + " (copy)",
                });
              }}
            >
              Duplicate
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setBrowseModeOverride({ type: "delete", ids: [node.id] });
            }}
          >
            Delete
          </MenuItem>
        </>
      )}

      {canSelectAllChildren && !!node.children?.length && (
        <>
          {node?.id !== "$ROOT" && <MenuDivider />}
          <MenuItem
            onClick={() => {
              const obj = {};
              node.children.forEach((nodeId) => (obj[nodeId] = true));
              setSelectedNodeIds(obj);
            }}
          >
            Select all
          </MenuItem>
        </>
      )}
    </MenuList>
  );
}

function DownloadMenuItem(props) {
  const { node } = props;

  const downloadURL = useUploadedFileUrl(node.id);

  return (
    <MenuItem as="a" download={node.name} href={downloadURL}>
      Download
    </MenuItem>
  );
}
