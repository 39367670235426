import React, {
  useMemo,
  useEffect,
  createContext,
  useRef,
  useState,
} from "react";

const activityEvents = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
  "resize",
];

export const RecentInteractionContext = createContext();

export default function RecentInteractionProvider(props) {
  const { children } = props;

  const timeoutRef = useRef();
  const [hasRecentInteraction, setHasRecentInteraction] = useState();

  useEffect(() => {
    const listener = () => {
      setHasRecentInteraction(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () => setHasRecentInteraction(false),
        1 * 1000
      );
    };

    activityEvents.forEach((eventName) =>
      document.addEventListener(eventName, listener, true)
    );

    return () => {
      activityEvents.forEach((eventName) =>
        document.removeEventListener(eventName, listener, true)
      );
    };
  });

  const value = useMemo(() => ({ hasRecentInteraction }), [
    hasRecentInteraction,
  ]);

  return (
    <RecentInteractionContext.Provider value={value}>
      {children}
    </RecentInteractionContext.Provider>
  );
}
