import { EventBatcher } from "./eventStreams";

export type DataId = number;

export enum DeltaType {
  Set,
  Update,
  Delete,
}

type UserId = "";

interface DeltaBase {
  type: DeltaType;
  dataId: DataId;
  timestamp: Date;
  userId: UserId;
}

export interface SetDelta<T> extends DeltaBase {
  type: DeltaType.Set;
  data: T;
}

export interface UpdateDelta<T> extends DeltaBase {
  type: DeltaType.Update;
  data: Partial<T>;
}

export interface DeleteDelta extends DeltaBase {
  type: DeltaType.Delete;
}

export type Delta<T> = SetDelta<T> | UpdateDelta<T> | DeleteDelta;

// Returns the a delta corresponding to two other deltas performed in order
export function mergeDeltas<T>(first: Delta<T>, second: Delta<T>): Delta<T> {
  switch (second.type) {
    case DeltaType.Set:
    case DeltaType.Delete:
      return second;

    case DeltaType.Update:
      switch (first.type) {
        case DeltaType.Set:
          return {
            ...second,
            type: DeltaType.Set,
            data: { ...first.data, ...second.data },
          };

        case DeltaType.Update:
          return {
            ...second,
            data: { ...first.data, ...second.data },
          };

        default:
          return {
            ...second,
            type: DeltaType.Update,
            data: second.data,
          };
      }
  }
}

export class DeltaBatcher<T> extends EventBatcher<Delta<T>> {
  set(dataId: DataId, data: T) {
    return this.event({
      type: DeltaType.Set,
      dataId,
      data,
      timestamp: new Date(),
      userId: "",
    });
  }

  update(dataId: DataId, data: Partial<T>) {
    return this.event({
      type: DeltaType.Update,
      dataId,
      data,
      timestamp: new Date(),
      userId: "",
    });
  }

  delete(dataId: DataId) {
    return this.event({
      type: DeltaType.Delete,
      dataId,
      timestamp: new Date(),
      userId: "",
    });
  }
}

// A map of data items by id
export type DataImage<D> = Map<DataId, D>;
export type DataHistoryImage<D> = Map<DataId, Delta<D>[]>;

export function blankDataImage<D>(): DataImage<D> {
  return new Map();
}

export function blankDataHistoryImage<D>(): DataHistoryImage<D> {
  return new Map();
}

export function applyToDataImage<D>(delta: Delta<D>, image: DataImage<D>) {
  const { dataId } = delta;

  switch (delta.type) {
    case DeltaType.Set:
      image.set(dataId, delta.data);
      break;
    case DeltaType.Update:
      const oldData = image.get(dataId);
      if (!oldData) break;
      const newData: D = { ...oldData };
      for (const [key, value] of Object.entries(delta.data)) {
        if (value === undefined) continue;
        // @ts-ignore
        newData[key] = value;
      }
      break;
    case DeltaType.Delete:
      image.delete(dataId);
      break;
  }
}

export function applyToDataHistoryImage<D>(
  delta: Delta<D>,
  image: DataHistoryImage<D>
) {
  const { dataId } = delta;
  if (!image.has(dataId)) image.set(dataId, []);
  image.get(dataId)?.push(delta);
}

// Merges deltas based on age, and removes any consequent delete/update deltas (or delete/update deltas at the front)
// export function optimizeHistory() {
/*
    For each node
      For each history event
        const age = now - event
        const granularity = Math.ceil(age / 30)

        if (this is an update or delete delta)
          if (there is no previous node, or the previous node is a delete)
            drop this delta

        if (age < a minute) ignore
        if (has previous node and distance < granularity)
          merge into previous node
        else
          set as the previous node

  */
// }

// export function getHistoricalNode<T>(
//   deltas: Delta<T>[],
//   lastDeltaIndex: number = deltas.length - 1
// ): Node | null {
//   let result = null;

// find the previous destructive event from the lastDeltaIndex
// build the node, moving forward

// can not have separate applyDelta function, because it must be able to delete the node.

//   return result;
// }

/*

  reasons to make own solution instead of pouchdb

  - one infinite workspace
  - fast
  - subscriptions


  Initial server version:
    keep all data and nodes in memory
    regenerate from history on boot

    file persistence:
      history.bin    all events ever (streamed)
                     back up now and then


  Optimizations:
    larger machine
    segmented history
    swap nodes in and out of memory
    use zig


*/
