import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";

const synth = window.speechSynthesis;

export const DeviceContext = createContext();

export default function DeviceProvider(props) {
  const { children } = props;

  const [mouseDetected, setMouseDetected] = useLocalStorage(
    "mouse_detected",
    false
  );

  useEffect(() => {
    function mouseMoved() {
      setMouseDetected(true);
      document.removeEventListener("mousemove", mouseMoved);
    }

    document.addEventListener("mousemove", mouseMoved);
    return () => document.removeEventListener("mousemove", mouseMoved);
  }, [setMouseDetected]);

  const availableVoices = synth && synth.getVoices();
  const preferred =
    synth &&
    synth
      .getVoices()
      .findIndex((voice) => voice.name === "Google UK English Female");
  const defaultVoiceIndex = preferred === -1 ? 0 : preferred;

  const [voiceIndex, setVoiceIndex] = useLocalStorage(
    "voiceIndex",
    defaultVoiceIndex
  );

  const speak = useCallback(
    (message) => {
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.voice = availableVoices[voiceIndex];
      synth.speak(utterance);
    },
    [availableVoices, voiceIndex]
  );

  const value = useMemo(
    () => ({ voiceIndex, setVoiceIndex, speak, mouseDetected }),
    [voiceIndex, setVoiceIndex, speak, mouseDetected]
  );

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
}
