import { CloseIcon, SearchIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useLocation, useWindowSize } from "react-use";
import { useUser } from "reactfire";
import { ActiveWorkspaceContext } from "../providers/ActiveWorkspaceProvider";
import { RecentInteractionContext } from "../providers/RecentInteractionProvider";
import { getSearchUrl } from "../utils/urlGetters";
import { pluralize } from "../utils/utils";
import {
  NewIconButton,
  PathCrumbsNav,
  RenameNodeInput,
  SimpleHeaderNavbarContent,
} from "./ContextualNavbar";
import { JobProgressIndicator } from "./JobProgressIndicator";

function NavbarWrapper(props) {
  const { children, hideWhenInactive, ...rest } = props;

  const { colorMode } = useColorMode();

  const bgColor = {
    light: "rgba(255, 255, 255, 0.8)",
    dark: "#1A202C",
  }[colorMode];

  const bgGradient = {
    light:
      "linear-gradient(180deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.8) 100%)",
  }[colorMode];

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { hasRecentInteraction } = useContext(RecentInteractionContext);

  return (
    <Flex
      flexDirection="column"
      // overflow="hidden"
      // height="4rem"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      transition="opacity 500ms"
      // pointerEvents={isHovered ? undefined : "none"}
      opacity={
        isHovered || isFocused || hasRecentInteraction || !hideWhenInactive
          ? 1
          : 0
      }
      zIndex={100}
      position="fixed"
      top="0"
      left="0"
      right="0"
      width="100vw"
      backgroundColor={bgColor}
      bg={bgGradient}
      boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      style={{
        backdropFilter: "blur(4px)",
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export function CollapsingButton(props) {
  const { icon, threshold = 700, label, noIconWhenLarge, ...rest } = props;

  const { width } = useWindowSize();

  if (width < threshold) {
    return (
      <Tooltip position="bottom" label={label}>
        <IconButton icon={icon} {...rest} />
      </Tooltip>
    );
  }

  return (
    <Button leftIcon={noIconWhenLarge ? undefined : icon} {...rest}>
      {label}
    </Button>
  );
}

export function Navbar(props) {
  const { bodyMargin = "4rem", hideWhenInactive, subMenu } = props;

  const { workspaceId } = useParams();
  const { width, height } = useWindowSize();

  const user = useUser();

  const { displayName, email, photoURL } = user;

  const hasVScroll = document.body.scrollHeight > height;

  const { pathname } = useLocation();

  const isSearching = pathname.startsWith(`/${workspaceId}/search`);
  const isAccount = pathname.startsWith(`/${workspaceId}/settings`);

  const {
    selectedNodeIds,
    setSelectedNodeIds,
    browseModeOverride,
    setBrowseModeOverride,
  } = useContext(ActiveWorkspaceContext);

  const hasSpecialView = browseModeOverride || selectedNodeIds;

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        if (selectedNodeIds) {
          setSelectedNodeIds(null);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [selectedNodeIds, setSelectedNodeIds]);

  const overlayColor = selectedNodeIds ? "rgba(128, 90, 213, 0.4)" : null;

  // const noResults = query && !results.length;
  // const commandMode = searchParams?.lowercaseQuery?.startsWith(":");

  return (
    <>
      <NavbarWrapper
        hideWhenInactive={hideWhenInactive}
        pr={hasVScroll ? "0.5rem" : "0"}
      >
        <Flex p="0.75rem" backgroundColor={overlayColor} alignItems="center">
          {browseModeOverride?.type === "rename" && (
            // TODO: don't show if not matching current node
            // browseModeOverride.ids?.length === 1 && browseModeOverride.ids[0] === ... &&
            <RenameNodeInput nodeId={browseModeOverride.ids} />
          )}

          {selectedNodeIds && (
            <>
              <Button
                mr="0.75rem"
                // leftIcon={<CloseIcon />}
                onClick={() => setSelectedNodeIds(null)}
              >
                Cancel
              </Button>

              <Menu>
                <MenuButton
                  rightIcon={<TriangleDownIcon />}
                  as={Button}
                  colorScheme="purple"
                  mr="0.75rem"
                >
                  {pluralize(
                    Object.values(selectedNodeIds).filter((v) => v).length,
                    "item",
                    true
                  )}{" "}
                  selected
                </MenuButton>

                <MenuList zIndex={1000} placement="bottom-end">
                  <MenuItem
                    onClick={() => {
                      setSelectedNodeIds(null);
                      setBrowseModeOverride({
                        type: "delete",
                        ids: Object.keys(selectedNodeIds).filter(
                          (id) => selectedNodeIds[id]
                        ),
                      });
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}

          <Switch>
            <Route path={`/:workspaceId/files/:nodeId`}>
              {!hasSpecialView && <PathCrumbsNav />}
            </Route>

            <Route path={`/:workspaceId/settings`}>
              <Flex
                flexGrow="1"
                textAlign="left"
                alignItems="center"
                className="slide-from-right"
              >
                <Avatar
                  size="sm"
                  name={displayName}
                  src={photoURL}
                  mr="0.25rem"
                  width="2.5rem"
                  height="2.5rem"
                  // display={["none", null, "block"]}
                />

                <Box flexGrow="1" ml="0.25rem">
                  <Box fontWeight="600">{displayName || email}</Box>
                  <Box fontSize="xs">{email}</Box>
                </Box>
              </Flex>
            </Route>

            <Route path={`/:workspaceId/calendar`}>
              <SimpleHeaderNavbarContent text="Calendar" />
            </Route>

            <Route path={`/:workspaceId/trash`}>
              <SimpleHeaderNavbarContent text="Recycle bin" />
            </Route>
          </Switch>

          {!hasSpecialView && (
            <>
              <Box flexGrow={1} />

              <Route path={`/:workspaceId/files/:nodeId`}>
                <NewIconButton />
              </Route>

              <JobProgressIndicator />
              {!isAccount && <SearchInput className="fade-in" mr="0.5rem" />}

              <Switch>
                <Route path={`/:workspaceId/search`} />
                <Route path={`/:workspaceId/settings`}>
                  <IconButton
                    icon={<CloseIcon />}
                    as={Link}
                    to={`/${workspaceId}/files`}
                  />
                </Route>
                <Route>
                  <AccountButton />
                </Route>
              </Switch>

              {isSearching &&
                (width < 400 ? (
                  <IconButton
                    icon={<CloseIcon />}
                    as={Link}
                    to={`/${workspaceId}/files`}
                  />
                ) : (
                  <Button as={Link} to={`/${workspaceId}`}>
                    Cancel
                  </Button>
                ))}
            </>
          )}
        </Flex>

        {!!subMenu && (
          <Flex p="0.75rem" backgroundColor="rgba(127, 127, 127, 0.1)">
            {subMenu}
          </Flex>
        )}
      </NavbarWrapper>

      {bodyMargin && !hideWhenInactive && (
        <Box flexShrink="0" height={subMenu ? "7.5rem" : "4rem"} />
      )}
    </>
  );
}

export function SearchInput(props) {
  const { ...rest } = props;

  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const { colorMode } = useColorMode();

  const isSearching = pathname.startsWith(`/${workspaceId}/search`);

  const match = useRouteMatch(`/${workspaceId}/search/:query`);
  const encodedQuery = match?.params?.query;

  const searchQuery = encodedQuery ? decodeURIComponent(encodedQuery) : "";
  const isCommandMode = searchQuery.startsWith(":");

  const inputRef = useRef();
  const inputWidth = inputRef.current?.offsetWidth;

  let placeholder = isSearching ? "Type to search..." : "Find anything...";
  if (inputWidth < 100) placeholder = "";
  else if (inputWidth < 160) placeholder = "Find...";

  return (
    <InputGroup
      {...rest}
      flexShrink={10000}
      flexGrow={10000}
      minWidth="2.5rem"
      maxWidth={isSearching ? "calc(100vw - 9ch)" : "26ch"}
      transition="max-width 200ms"
      width={null}
      mr="0.75rem"
      // overflow="hidden"
    >
      <InputLeftElement
        onClick={() => {
          inputRef.current?.click();
          inputRef.current?.focus();
        }}
      >
        <SearchIcon color={isCommandMode ? "green.500" : "blue.500"} />
      </InputLeftElement>
      <Input
        id="app-search-input"
        ref={inputRef}
        autoComplete="off"
        type="search"
        flexGrow={1}
        flexShrink={1}
        width={undefined}
        overflow="hidden"
        pr={searchQuery ? undefined : 0}
        // paddingRight={width < 500 && !isSearching ? 0 : undefined}
        // maxWidth={isSearching || !compact ? undefined : "0"}
        focusBorderColor={isCommandMode ? "green.500" : undefined}
        backgroundColor={colorMode === "light" ? "white" : undefined}
        placeholder={placeholder}
        borderRadius={isCommandMode ? "0.5rem" : "1rem"}
        fontFamily={
          isCommandMode
            ? "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;"
            : undefined
        }
        value={searchQuery}
        onChange={(event) => {
          history.replace(getSearchUrl(workspaceId, event.target.value));
        }}
        onClick={(event) => {
          history.push(getSearchUrl(workspaceId, event.target.value));
        }}
        onKeyDown={(event) => {
          if (["ArrowDown", "Enter"].includes(event.key)) {
            const toBeFocused = document.body.querySelector(
              `[data-item-button-index="0"]`
            );
            if (toBeFocused) {
              event.preventDefault();
              toBeFocused.focus();
            }
          }
        }}
      />
    </InputGroup>
  );
}

function AccountButton(props) {
  const { workspaceId } = useParams();
  const { displayName, photoURL } = useUser();
  const { pathname } = useLocation();

  const isOnMenuPage = pathname === `/${workspaceId}/settings`;

  // const mode = readFromLocalStorage("workspaceMode_" + workspaceId, "files");

  return (
    <Avatar
      className="fade-in"
      size="sm"
      width="2.25rem"
      height="2.25rem"
      name={displayName}
      // border={"1px solid rgba(255, 255, 255, 1)"}
      // m="0"
      // mr="0.5rem"
      as={Link}
      to={isOnMenuPage ? `/${workspaceId}/files` : `/${workspaceId}/settings`}
      src={photoURL}
      transition="all 200ms"
      _hover={{
        boxShadow: "rgba(66, 153, 225, 0.6) 0px 0px 0px 3px;",
      }}
      _focus={{
        outline: "none",
        boxShadow: "rgba(66, 153, 225, 0.6) 0px 0px 0px 3px;",
      }}
    />
  );
}
