import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { SuspenseWithPerf } from "reactfire";
import { AccountSettings } from "../account/AccountSettings";
import JobQueue from "../account/JobQueue";
import CalendarPage from "../calendar/CalendarPage";
import { DeleteNodeDialog } from "../files/dialogs/DeleteNodeDialog";
import { FilesPage } from "../files/FilesPage";
import RecycleBinPage from "../files/RecycleBinPage";
import { SearchResultsPage } from "../search/SearchResultsPage";
import { Center, CenteredSpinner, saveToLocalStorage } from "../utils/utils";

export function MainAreaRouter() {
  const {
    path,
    url,
    // params: { workspaceId },
  } = useRouteMatch();

  const trimmedUrl = url.replace(/\/$/, "");

  useWorkspaceModePersistence();

  return (
    <>
      <DeleteNodeDialog />

      <SuspenseWithPerf
        fallback={<CenteredSpinner />}
        traceId={"suspense-main-area"}
      >
        <Switch>
          <Route path={path + "/search/:encodedQuery?"}>
            <SearchResultsPage />
          </Route>

          <Route exact path={path + "/settings"} component={AccountSettings} />
          <Route exact path={path + "/jobs"} component={JobQueue} />
          <Route path={path + "/trash"} component={RecycleBinPage} />

          <Route path={path + "/files"} component={FilesPage} />
          <Route path={path + "/calendar"} component={CalendarPage} />

          <Route exact path={path}>
            <Redirect
              to={
                trimmedUrl + "/files"
                // readFromLocalStorage("workspaceMode_" + workspaceId, "files")
              }
            />
          </Route>

          <Route>
            <Center>
              <Text>TODO: 404 page</Text>
            </Center>
          </Route>
        </Switch>
      </SuspenseWithPerf>
    </>
  );
}

function useWorkspaceModePersistence() {
  const { workspaceId, mode } =
    useRouteMatch("/:workspaceId/:mode")?.params || {};

  useEffect(() => {
    if (!workspaceId || !mode) return;
    saveToLocalStorage("workspaceMode_" + workspaceId, mode);
  }, [workspaceId, mode]);
}
