import { DeleteIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Select,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { FiLogOut } from "react-icons/fi";
import { MdFolder, MdPlayArrow } from "react-icons/md";
import { PieChart } from "react-minimal-pie-chart";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth, useUser } from "reactfire";
import { CollapsingButton } from "../navbar/Navbar";
import { DeviceContext } from "../providers/DeviceProvider";
import { Option } from "../providers/FormProvider";
import { SettingsSubHeader } from "../ui/settingsComponents";
import { usePaperColor } from "../utils/appColors";
import { colorModeIcons, TimeAgo } from "../utils/utils";

export const accountSidebarIndex = [
  "Account",
  ["Payments", "Usage"],
  ["Device settings"],

  "Workspaces",
  ["Credits and usage"],
  ["Appearance", "Enabled features"],
];

const synth = window.speechSynthesis;

function DefaultAccordionButton(props) {
  const { children, ...rest } = props;
  return (
    <h2 {...rest}>
      <AccordionButton
      // _expanded={{ fontSize: "1.2rem" }}
      >
        <Flex flex="1" textAlign="left" fontWeight="600" my="0.5rem">
          {children}
        </Flex>
        <AccordionIcon />
      </AccordionButton>
    </h2>
  );
}

export function AccountSettings() {
  const history = useHistory();
  const { workspaceId } = useParams();
  const auth = useAuth();
  const user = useUser();

  const {
    metadata: { creationTime, lastSignInTime },
  } = user;

  // const workspaces = useContext(WorkspacesContext);
  // const { activeWorkspace } = useContext(ActiveWorkspaceContext);

  const creationDate = useMemo(() => new Date(creationTime), [creationTime]);
  const signInDate = useMemo(() => new Date(lastSignInTime), [lastSignInTime]);

  const { voiceIndex, setVoiceIndex } = useContext(DeviceContext);

  const availableVoices = synth && synth.getVoices();

  const bgColor = usePaperColor();

  const { colorMode, toggleColorMode } = useColorMode();
  const ColorModeIcon = colorModeIcons[colorMode];

  return (
    <>
      <HStack m="0.75rem 0.75rem 0" maxWidth="100ch">
        <CollapsingButton
          as={Link}
          to={`/${workspaceId}/files`}
          icon={<MdFolder />}
          label="Files"
          variant="outline"
          colorScheme="blue"
        />

        {/* <CollapsingButton
          as={Link}
          to={`/${workspaceId}/calendar`}
          icon={<CalendarIcon />}
          label="Desktop"
          variant="outline"
          colorScheme="blue"
        /> */}

        <CollapsingButton
          as={Link}
          to={`/${workspaceId}/trash`}
          icon={<DeleteIcon />}
          label="Recycle bin"
          variant="outline"
          colorScheme="blue"
          mr="auto"
        />

        <CollapsingButton
          // size="sm"
          variant="ghost"
          colorScheme="purple"
          icon={<ColorModeIcon />}
          onClick={toggleColorMode}
          label={{ dark: "Day", light: "Night" }[colorMode] + " mode"}
        />
      </HStack>

      <Flex
        className="SettingsArea slide-in"
        flexGrow="1"
        flexDirection="column"
        // overflow="auto"
        // width="100vw"
        maxWidth="100ch"
        m="0.75rem"
        // alignItems="center"
      >
        {/* <SettingsIndex
          display={["none", null, "block"]}
          items={accountSidebarIndex}
        /> */}

        {/* <SettingsCard> */}
        <Accordion
          borderRadius="0.5rem"
          boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
          allowToggle
          backgroundColor={bgColor}
          // defaultIndex={0}
        >
          {/* <AccordionItem>
            <DefaultAccordionButton>
              Workspace: {activeWorkspace.name}
            </DefaultAccordionButton>

            <AccordionPanel>
              <Box mb="1.25rem">
                {workspaces.docs.map((workspace) => (
                  <Button
                    key={workspace.id}
                    as={Link}
                    replace
                    colorScheme="blue"
                    variant="ghost"
                    // size="sm"
                    value={workspace.id}
                    to={"/" + workspace.id}
                    isActive={workspaceId === workspace.id}
                    title={workspace.description}
                  >
                    {workspace.get("name")}
                  </Button>
                ))}

                <Button
                  borderRadius="full"
                  variant="ghost"
                  // colorScheme="blue"
                  // size="sm"
                  leftIcon={<AddIcon />}
                  // ml="0.75rem"
                  // my="0.75rem"
                >
                  New workspace
                </Button>
              </Box>

              <Box mb="1.25rem">
                Created <TimeAgo date={new Date(2019, 5, 5)} />
              </Box>

              <FormProvider initialValues={{}} onSave={(val) => {}}>
                <SettingsSubHeader>
                  Choose which features you want for this workspace.
                </SettingsSubHeader>

                <Box mb="1.25rem">
                  <Box mb="0.75rem">Functionality:</Box>
                  <SwitchControl>Enable X</SwitchControl>
                </Box>

                <Box mb="1.25rem">
                  <Box mb="0.75rem">File types:</Box>
                  <SwitchControl>Enable activities</SwitchControl>
                  <SwitchControl>
                    Enable file uploads (images, videos, documents etc)
                  </SwitchControl>
                </Box>
              </FormProvider>
            </AccordionPanel>
          </AccordionItem> */}

          <AccordionItem>
            <DefaultAccordionButton>Account</DefaultAccordionButton>

            <AccordionPanel>
              <Flex my="1.25rem" flexWrap="wrap" alignItems="baseline">
                <Button
                  // size="sm"
                  leftIcon={<FiLogOut />}
                  onClick={async () => {
                    await auth.signOut();
                    history.push("/");
                  }}
                  colorScheme="blue"
                  // variant="outline"
                  mr="1.25rem"
                >
                  Sign out
                </Button>

                <Box>
                  Last signed in <TimeAgo date={signInDate} />
                </Box>
              </Flex>

              {/* <Flex mb="2.5rem">
                <Box
                  opacity="0.3"
                  mr="1.75rem"
                  display={["none", null, "block"]}
                >
                  <CreditCard size={120} mood="sad" />
                </Box>

                <Box>
                  <Text fontWeight="600">Missing card details.</Text>
                  <Text mb="1.25rem">Unable to extract money.</Text>
                  <Button colorScheme="blue">Add a payment method</Button>
                </Box>
              </Flex> */}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <DefaultAccordionButton>Usage</DefaultAccordionButton>

            <AccordionPanel>
              <Flex mb="2.5rem" alignItems="center">
                <Box
                  opacity="0.7"
                  maxWidth="10rem"
                  mr="1.75rem"
                  display={["none", null, "block"]}
                >
                  <PieChart
                    data={[
                      { title: "One", value: 10, color: "#E38627" },
                      { title: "Two", value: 15, color: "#C13C37" },
                      { title: "Three", value: 20, color: "#6A2135" },
                    ]}
                  />
                </Box>

                <Flex flexDirection="column">
                  <Text fontWeight="600">This is a chart</Text>
                  <Text mb="1.25rem">It does not represent anything.</Text>
                  <Button isDisabled colorScheme="blue" alignSelf="flex-start">
                    Add credits
                  </Button>
                </Flex>
              </Flex>

              <Text>
                You signed up for DataCube <TimeAgo date={creationDate} />.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <DefaultAccordionButton>Device settings</DefaultAccordionButton>

            <AccordionPanel>
              <SettingsSubHeader>
                These settings are local to this computer or phone.
              </SettingsSubHeader>

              <Box>
                <FormControl>
                  <FormLabel htmlFor="voice">Text-to-speech voice</FormLabel>
                  <Flex>
                    <Select
                      // as="select"
                      id="voice"
                      aria-describedby="voice-helper-text"
                      mr="0.75rem"
                      value={voiceIndex}
                      onChange={(event) => setVoiceIndex(event.target.value)}
                    >
                      {availableVoices
                        .filter(
                          (voice) => voice.lang && voice.lang.startsWith("en")
                        )
                        .map((voice, i) => (
                          <Option
                            key={i}
                            value={availableVoices.indexOf(voice)}
                          >
                            {voice.name || voice.voiceURI}
                          </Option>
                        ))}
                    </Select>
                    <Tooltip label="Test voice">
                      <IconButton
                        colorScheme="blue"
                        icon={MdPlayArrow}
                        onClick={() => {
                          const voice = availableVoices[voiceIndex];
                          const utterance = new SpeechSynthesisUtterance(
                            "You have selected " + voice.name
                          );

                          utterance.voice = voice;

                          synth.speak(utterance);
                        }}
                      />
                    </Tooltip>
                  </Flex>

                  <FormHelperText id="voice-helper-text">
                    The voice used to read text aloud.
                  </FormHelperText>
                </FormControl>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          {/* <AccordionItem>
              <DefaultAccordionButton>Device settings</DefaultAccordionButton>

              <AccordionPanel></AccordionPanel>
            </AccordionItem> */}
        </Accordion>
      </Flex>
    </>
  );
}
