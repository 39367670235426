import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useFirestore, useUser } from "reactfire";
import { FullScreenSpinner } from "../utils/utils";
import { WorkspacesContext } from "./WorkspacesProvider";

export function RedirectToWorkspaceOrCreate() {
  const db = useFirestore();
  const { uid, displayName } = useUser();
  const workspaces = useContext(WorkspacesContext);

  useEffect(() => {
    if (workspaces.docs.length) return;

    const batch = db.batch();

    batch.set(db.collection("workspaces").doc(uid), {
      name: displayName.split(" ")[0],
      description: "Auto-created user workspace",
      ownerUids: [uid],
    });

    batch.set(db.collection("workspaceData").doc(uid), {});

    batch.commit();
  }, [db, workspaces.docs.length, uid, workspaces.ref, displayName]);

  const lastId = localStorage.getItem("lastWorkspaceId-" + uid);

  return workspaces.docs.length ? (
    <Redirect
      to={
        "/" +
        (workspaces.docs.find((doc) => doc.id === lastId)
          ? lastId
          : workspaces.docs[0].id)
      }
    />
  ) : (
    <FullScreenSpinner />
  );
}
