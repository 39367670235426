import { Box, useColorMode } from "@chakra-ui/react";
import hash from "hash-sum";
import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GenericMasonry,
  sortNodesIntoTiles,
} from "../files/editors/shared/FileBrowser";
import { ItemButton } from "../ItemButton";
import {
  ActiveWorkspaceContext,
  NodeListingContext,
} from "../providers/ActiveWorkspaceProvider";
import { resourceTypes } from "../utils/resourceUtils";
import { getNodeUrl } from "../utils/urlGetters";
import {
  capitalize,
  Card,
  CenteredMessage,
  colorModeIcons,
  humanize,
  randomId,
  readFromLocalStorage,
} from "../utils/utils";

export function SearchResultsPage() {
  const { encodedQuery } = useParams();
  const history = useHistory();
  const { selectedNodeIds } = useContext(ActiveWorkspaceContext);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape" && !selectedNodeIds) {
        history.goBack();
        document.getElementById("app-search-input")?.blur();
        event.stopPropagation();
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [history, selectedNodeIds]);

  const searchQuery = encodedQuery ? decodeURIComponent(encodedQuery) : "";
  const isCommandMode = searchQuery.startsWith(":");

  const lowercaseQuery = searchQuery?.toLocaleLowerCase() || "";

  const magicCommand = isCommandMode && lowercaseQuery.slice(1);

  return (
    <NodeListingContext.Provider value={{}}>
      <Box p="0.75rem">
        {!searchQuery ? (
          <RecentItems query={magicCommand} />
        ) : isCommandMode ? (
          <CommandResults query={magicCommand} />
        ) : (
          <SearchResults query={lowercaseQuery} />
        )}
      </Box>
    </NodeListingContext.Provider>
  );
}

function searchNodes(searchQuery, fileTree) {
  const nodeResults = Object.values(fileTree).filter((node) => {
    if (node.id === "$ROOT") return false;
    const comparedString = (node.name || "").toLocaleLowerCase();
    return comparedString.includes(searchQuery);
  });

  const sortedNodes = nodeResults.sort((a, b) => {
    return (a.name || "").localeCompare(b.name || "");
  });

  return sortedNodes.slice(0, 30);
}

export function commandActionResult(command) {
  return {
    id: command,
    title: capitalize(command),
    icon: command,
  };
}

function CommandResults(props) {
  const { query } = props;
  const history = useHistory();
  const { workspaceId } = useParams();
  const { colorMode, toggleColorMode } = useColorMode();
  const { setBrowseModeOverride, setNode } = useContext(ActiveWorkspaceContext);

  const commandResults = [
    {
      ...commandActionResult("toggle-color-mode"),
      icon: colorModeIcons[colorMode],
      title: { dark: "Turn on the light", light: "Turn off the light" }[
        colorMode
      ],
      execute: () => {
        toggleColorMode();
      },
    },

    ...resourceTypes.map((resourceType) => {
      let resourceName = humanize(resourceType);

      const isSubActivity = false; // TODO
      // resourceType === "activity" && openFolder.type === "activity";

      if (isSubActivity) resourceName = "sub-activity";

      return {
        ...commandActionResult("new " + resourceType.replace("_", "-")),
        icon: resourceType,

        execute: async () => {
          const id = randomId();

          await setNode({
            id,
            type: resourceType,
            name: "",
            parent: readFromLocalStorage("folder_" + workspaceId) || "$ROOT", // TODO: move to helper
          });

          history.replace(getNodeUrl(workspaceId, id));

          setBrowseModeOverride({
            type: "rename",
            ids: [id],
          });
        },

        title: "New " + resourceName,
      };
    }),
  ].filter((command) => command.title.toLocaleLowerCase().includes(query));

  if (!commandResults?.length) {
    return (
      <CenteredMessage>
        No commands found. You searched for <code>{query}</code>.
      </CenteredMessage>
    );
  }

  return (
    <Card maxWidth="400px">
      {commandResults.map((result, i) => {
        return (
          <ItemButton
            onClick={result.execute}
            key={result.id}
            name={result.title}
            icon={result.icon || result.resourceType}
            index={i}
            flexShrink="0"
          />
        );
      })}
    </Card>
  );
}

const secsPerDay = 24 * 60 * 60;
// Every 12 hours since being opened is worth the same as having been opened one time, negatively.
const worthOfSec = 1 / (secsPerDay * 0.2);

// TODO: seconds closer to now should be worth much more than older ones (quadratic function)
function recentNodeSortIndex(node) {
  const i =
    (node.counts?.opened || 0) * (node.type === "folder" ? 0.5 : 1) +
    (node.timestamps?.opened?.seconds || 0) * worthOfSec;
  return i;
}

function getRecentNodes(fileTree) {
  return Object.values(fileTree)
    .sort((a, b) => recentNodeSortIndex(b) - recentNodeSortIndex(a))
    .slice(0, 30);
}

export function RecentItems() {
  const { fileTree } = useContext(ActiveWorkspaceContext);

  // TODO: avoid sorting two times?
  const results = getRecentNodes(fileTree);

  const tiles = useMemo(
    () =>
      sortNodesIntoTiles(
        results,
        (a, b) => recentNodeSortIndex(b) - recentNodeSortIndex(a)
      ),
    [results]
  );

  return <GenericMasonry tiles={tiles} />;
}

export function SearchResults(props) {
  const { query } = props;
  const { fileTree } = useContext(ActiveWorkspaceContext);

  const results = searchNodes(query, fileTree);

  const masonryKey = useMemo(() => hash(results.map((node) => node.id)), [
    results,
  ]);
  const tiles = useMemo(() => sortNodesIntoTiles(results), [results]);

  // const commandResults = results.filter((result) => !result.node);

  if (!results.length) {
    return (
      <CenteredMessage>
        No results. You searched for <em>{query}</em>.
      </CenteredMessage>
    );
  }

  return <GenericMasonry key={masonryKey} tiles={tiles} />;
}
