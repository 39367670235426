import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { usePaperColor } from "../utils/appColors";
import { FadePresence } from "../utils/utils";
import { useEditorLock } from "./EditorLockProvider";

export const FormContext = createContext();

export default function FormProvider(props) {
  const {
    unsavedChangesLockId,
    initialValues = {},
    validate,
    onSave,
    onCancel,
    children,
    alwaysUnlocked = false,
    ...rest
  } = props;

  const [showChangesSaved, setShowChangesSaved] = useState(false);

  const [fields, setFields] = useState(initialValues);
  const [isEditing, setIsEditing] = useState(alwaysUnlocked);
  const [isSaving, setIsSaving] = useState(false);

  const hasChanges = isEditing && !isSaving && initialValues !== fields;

  useEditorLock(unsavedChangesLockId, hasChanges);

  const setField = useCallback((key, value) => {
    setFields((old) => ({
      ...old,
      [key]: typeof value === "function" ? value(old[key]) : value,
    }));
  }, []);

  const errors = useMemo(() => (validate ? validate(fields) : {}), [
    validate,
    fields,
  ]);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (onSave) {
        const result = onSave(fields, event);
        if (result?.then) {
          setIsSaving(true);
          await result;
          setIsSaving(false);
        }

        setShowChangesSaved(true);
      }
      setIsEditing(false);
    },
    [onSave, setIsEditing, fields, setShowChangesSaved]
  );

  const contextValue = useMemo(
    () => ({
      initialValues,
      alwaysUnlocked,
      fields,
      setField,
      setFields,
      isEditing,
      setIsEditing,
      isSaving,
      isDisabled: (!alwaysUnlocked && !isEditing) || isSaving,
      errors,
    }),
    [
      initialValues,
      alwaysUnlocked,
      fields,
      setFields,
      setField,
      isEditing,
      setIsEditing,
      isSaving,
      errors,
    ]
  );

  return (
    <FormContext.Provider value={contextValue}>
      <Box as="form" onSubmit={onSubmit} {...rest}>
        {children}

        <Flex alignItems="center">
          {/* <Box flexGrow="1"></Box> */}
          {isEditing || alwaysUnlocked ? (
            <>
              <ButtonGroup>
                <Button
                  disabled={isSaving}
                  onClick={() => {
                    setFields(initialValues);
                    if (alwaysUnlocked) {
                      onCancel && onCancel();
                    } else setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isSaving}
                  disabled={initialValues === fields}
                  colorScheme="blue"
                >
                  Save changes
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <Button
              onClick={() => {
                setIsEditing(true);
                setShowChangesSaved(false);
                setFields(initialValues);
              }}
              colorScheme="blue"
              mr="1.25rem"
            >
              Edit values
            </Button>
          )}

          {showChangesSaved && (
            <FadePresence display="flex" alignItems="center">
              <CheckCircleIcon color="green.500" mr="0.75rem" />
              <Box>Changes saved</Box>
            </FadePresence>
          )}
        </Flex>
      </Box>
    </FormContext.Provider>
  );
}

function formFieldProps(formContextValue, id) {
  const {
    errors: { [id]: error },
    isEditing,
    isDisabled,
    fields: { [id]: value = "" },
    setField,
  } = formContextValue;

  return {
    id,
    name: id,
    isInvalid: isEditing && error,
    isDisabled,
    value,
    onChange: (event) => setField(id, event.target.value),
  };
}

function LabelAndHelp(props) {
  const { id, label, fieldProps, help, helpId, children, ...rest } = props;

  return (
    <FormControl mb="1.25rem" mr="0.75rem" {...rest}>
      {!!label && (
        <FormLabel
          opacity={fieldProps.isDisabled ? 0.4 : 1}
          htmlFor={id}
          transition="opacity 200ms"
        >
          {label}
        </FormLabel>
      )}
      {children}
      {help && (
        <FormHelperText
          id={helpId}
          transition="opacity 200ms"
          opacity={fieldProps.isDisabled ? 0.4 : 1}
        >
          {help}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export function InputControl(props) {
  const { id, label, help, textArea, width, flexGrow, ...rest } = props;
  const formContextValue = useContext(FormContext);
  const fieldProps = formFieldProps(formContextValue, id);
  const helpId = id + "-helper-text";

  const Component = textArea ? Textarea : Input;

  return (
    <LabelAndHelp
      id={id}
      label={label}
      fieldProps={fieldProps}
      help={help}
      helpId={helpId}
      width={width}
      flexGrow={flexGrow}
    >
      <Component
        type="text"
        aria-describedby={help ? helpId : undefined}
        {...fieldProps}
        {...rest}
      />
    </LabelAndHelp>
  );
}

export function SelectControl(props) {
  const { id, label, help, children, ...rest } = props;
  const formContextValue = useContext(FormContext);
  const fieldProps = formFieldProps(formContextValue, id);
  const helpId = id + "-helper-text";

  return (
    <LabelAndHelp
      id={id}
      label={label}
      fieldProps={fieldProps}
      help={help}
      helpId={helpId}
    >
      <Select
        aria-describedby={help ? helpId : undefined}
        {...rest}
        {...fieldProps}
      >
        {children}
      </Select>
    </LabelAndHelp>
  );
}

export function Option(props) {
  const { style, children, ...rest } = props;
  const bgColor = usePaperColor();

  return (
    <option
      {...rest}
      style={{
        backgroundColor: bgColor,
        ...style,
      }}
    >
      {children}
    </option>
  );
}

export function SwitchControl(props) {
  const { id, help, children, ...rest } = props;
  const formContextValue = useContext(FormContext);
  const { setField, isEditing } = formContextValue;
  const { value, ...fieldProps } = formFieldProps(formContextValue, id);

  const helpId = id + "-helper-text";

  return (
    <Flex mb="0.75rem" alignItems="center">
      <Switch
        id={id}
        isChecked={value}
        value={value}
        mr="0.75rem"
        {...fieldProps}
        onChange={(event) => setField(id, event.target.checked)}
        {...rest}
      />
      {/* <Flex
      // alignItems="baseline"
      // flexDirection="column"
      > */}
      <FormLabel
        opacity={fieldProps.isDisabled ? 0.4 : 1}
        cursor={isEditing ? "pointer" : undefined}
        aria-describedby={help ? helpId : undefined}
        htmlFor={id}
        userSelect="none"
      >
        {children}
      </FormLabel>

      {help && (
        <FormHelperText
          // alignSelf="baseline"
          mt="0"
          transition="opacity 200ms"
          opacity={fieldProps.isDisabled ? 0.4 : 1}
          id={helpId}
        >
          {help}
        </FormHelperText>
      )}
      {/* </Flex> */}
    </Flex>
  );
}

export function FormHeading(props) {
  const { children, ...rest } = props;

  const { isDisabled } = useContext(FormContext);

  return (
    <Heading
      as="h2"
      fontSize="1.2rem"
      fontWeight="600"
      mb="1.25rem"
      transition="opacity 200ms"
      opacity={isDisabled ? 0.4 : 1}
      {...rest}
    >
      {children}
    </Heading>
  );
}
