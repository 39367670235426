import { CalendarIcon, DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import { Box, Icon } from "@chakra-ui/react";
import React from "react";
import {
  FaFile,
  FaFolder,
  FaPlus,
  FaRegClock,
  FaRegStickyNote,
  FaSitemap,
} from "react-icons/fa";
import { GiSoundWaves } from "react-icons/gi";
import {
  MdCallSplit,
  MdClose,
  MdHourglassFull,
  MdImage,
  MdOndemandVideo,
  MdSchool,
} from "react-icons/md";

export const datacubeDraggableMimeType = "application/vnd.datacube.draggable";

// Resource types that can have children
export const folderResourceTypes = ["folder", "activity"];

export const resourceTypes = ["folder", "note", "timed_routine", "activity"];

const icons = {
  note: FaRegStickyNote,
  timed_routine: FaRegClock,
  folder: FaFolder,
  new: FaPlus,
  close: MdClose,
  // competence: MdSchool,
  activity: MdSchool,
  uploaded_file: FaFile,

  // text: MdShortText,
  video: MdOndemandVideo,
  // checkbox: MdCheckBox,
  // timer: FaRegClock,
  audio: GiSoundWaves,
  image: MdImage,

  // signature: FaSignature,
  // action: MdSchool,
  // other_goal: TiArrowForwardOutline,

  all_of: FaSitemap,
  some_of: MdCallSplit,

  files: FaFolder,
  calendar: CalendarIcon,
  trash: DeleteIcon,
  jobs: MdHourglassFull,
  settings: SettingsIcon,
};

const iconColors = {
  folder: "yellow.300",
  files: "yellow.300",
  activity: "purple.500",
  all_of: "blue.500",
  some_of: "blue.500",
};

export function IconLookup(props) {
  const { icon, defaultColor, ...rest } = props;

  // TODO: get rid of this mess

  const IconComponent = icons[icon];
  const iconColor = iconColors[icon] || defaultColor;

  if (IconComponent) {
    return <Box as={IconComponent} color={iconColor} {...rest} />;
  } else {
    if (typeof icon === "string") {
      console.warn("Unknown icon:", icon);
      return <Icon name={icon} color={iconColor} {...rest} />;
    }
    // if (icon[0]) icon = icon[0];
    return <Box as={icon} color={iconColor} {...rest} />;
  }
}
