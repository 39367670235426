import { DataId } from "./deltas";

const EXCLUDED_INTERNAL = Symbol();
export const EXCLUDED = Symbol();

// Assigns data to groups in the index
export type PartitionGetter<D, K> = (data: D) => K;
export type PartitionState<K> = Map<K, Set<DataId>>;
export type DataMapper<D, D2> = (data: D) => D2 | typeof EXCLUDED;
export type DataFilter<D> = (data: D) => boolean;

export interface Reducer<D, M> {
  init: () => M;
  apply: (state: M, dataId: DataId, newNode?: D, oldNode?: D) => void;
  // query: (q: Q) => V;
}

export function createIdSetReducer<D>(): Reducer<D, Set<DataId>> {
  return {
    init: () => new Set(),
    apply: (state, dataId, newData, oldData) => {
      if (newData && !oldData) state.add(dataId);
      if (oldData && !newData) state.delete(dataId);
    },
    // TODO memo removal optimization
  };
}

export function createCountReducer<D>(): Reducer<D, { count: number }> {
  return {
    init: () => ({ count: 0 }),
    apply: (state, dataId, newData, oldData) => {
      if (newData && !oldData) state.count += 1;
      if (oldData && !newData) state.count -= 1;
    },
    // TODO memo removal optimization
  };
}

/*

filter(reducer, d => boolean) => reducer

             Answers
filter    -> is the new/old node included    node -> boolean
reducer   -> how to include it               init, add, remove
partition -> where to include it             getPartition (is technically a reducer constructor that takes reducers as parameters)

             partitioned(getPartition, partitionReducer)

Imagers: built in, produce nodes. Take reducers as parameters. Indexers = queries.

Filters:    eq(v), min(v), max(v), contains(v), one_of(v), custom...
Reducers:   count, idSet, sum, mappedSum(d => v), partition(getPartition, partitionReducer), custom...


Partitions are higher-order reducers. So are filters, actually..

filterReducer(
  fieldEq(type, "relationship"),
  partitionReducer(fieldValue("parentId"), idSetReducer)
)

partitionReducer(fieldValue("parentId"), idSetReducer)

*/

// Reducers:

export function partition<D, K, M>(
  getPartition: PartitionGetter<D, K>,
  reducer: Reducer<D, M>
  // filter parameter possible?
): Reducer<D, Map<K, M>> {
  function apply(state: Map<K, M>, dataId: DataId, newNode?: D, oldNode?: D) {
    const oldGroup = oldNode ? getPartition(oldNode) : EXCLUDED_INTERNAL;
    const newGroup = newNode ? getPartition(newNode) : EXCLUDED_INTERNAL;

    if (oldGroup !== newGroup) {
      if (newNode && newGroup !== EXCLUDED_INTERNAL) {
        if (!state.has(newGroup)) {
          const reducerState = reducer.init();
          if (reducerState) {
            state.set(newGroup, reducerState);
          }
        }
        const groupState = state.get(newGroup);
        groupState && reducer.apply(groupState, dataId, newNode);
      }

      if (oldGroup !== EXCLUDED_INTERNAL) {
        const groupState = state.get(oldGroup);
        groupState && reducer.apply(groupState, dataId, undefined, oldNode);
      }
    }
  }

  return {
    init: () => new Map(),
    apply,
  };
}

export function mapping<D, M, D2>(
  mapper: DataMapper<D, D2>,
  reducer: Reducer<D2, M>
): Reducer<D, M> {
  function apply(state: M, dataId: DataId, newNode?: D, oldNode?: D) {
    const newNodeMapped = newNode ? mapper(newNode) : EXCLUDED;
    const oldNodeMapped = oldNode ? mapper(oldNode) : EXCLUDED;

    newNodeMapped !== oldNodeMapped &&
      reducer.apply(
        state,
        dataId,
        newNodeMapped === EXCLUDED ? undefined : newNodeMapped,
        oldNodeMapped === EXCLUDED ? undefined : oldNodeMapped
      );
  }

  return {
    init: () => reducer.init(),
    apply,
  };
}

// export function selection<D, M, D2>(filter: DataFilter<D>, reducer: Indexer<D, M>) {
//   return mapping<D, M, D2>(
//     (data: D) => (filter(data) ? data : EXCLUDED),
//     reducer
//   );
// }
