import { CheckIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { JobQueueContext } from "../providers/JobQueueProvider";
import { ItemCard } from "../ui/settingsComponents";
import { Center, pluralize } from "../utils/utils";

function Job(props) {
  const {
    job: { label, status, key, callback, progress },
  } = props;
  const { pushJob, removeJob } = useContext(JobQueueContext);

  let backgroundColor = "rgba(150, 150, 150, 0.1)";
  if (status === "failure") backgroundColor = "rgba(255, 90, 90, 0.1)";
  if (status === "success") backgroundColor = "rgba(150, 255, 150, 0.1)";
  if (status === "active") backgroundColor = "rgba(200, 200, 255, 0.2)";

  return (
    <ItemCard
      direction="row"
      alignItems="center"
      mr="0"
      backgroundColor={backgroundColor}
      border="none"
      // borderWidth={"3px"}
    >
      {status === "failure" && (
        <Icon
          name="warning"
          size="1.5rem"
          color="red.500"
          m="0 1rem 0 0.5rem"
        />
      )}
      {status === "waiting" && (
        <Icon name="time" size="1.5rem" color="blue.500" m="0 1rem 0 0.5rem" />
      )}
      {status === "success" && (
        <CheckIcon size="1.5rem" color="green.500" m="0 1rem 0 0.5rem" />
      )}

      <Flex flexGrow="1">
        {status === "active" && (
          <CircularProgress
            m="0 1rem 0 0.5rem"
            isIndeterminate={progress === "indeterminate"}
            size="1.5rem"
            value={typeof progress === "number" ? progress * 100 : undefined}
          />
        )}

        <Text>{label}</Text>
      </Flex>

      {status !== "active" && (
        <Button variant={"ghost"} onClick={() => removeJob(key)}>
          {status === "success" ? "OK" : "Cancel"}
        </Button>
      )}

      {status === "failure" && (
        <Button
          variant="solid"
          colorScheme="blue"
          onClick={() => {
            pushJob("Retry: " + label, callback);
            removeJob(key);
          }}
        >
          Retry
        </Button>
      )}
    </ItemCard>
  );
}

function JobsCard(props) {
  const { jobs, children } = props;

  const slicedJobs = jobs.slice(0, 30);

  return (
    <Flex flexDirection="column" flexGrow="1" maxW="100ch" m="1rem">
      {children}

      <Flex direction="column" alignItems="stretch">
        {slicedJobs.map((job, i) => (
          <Job key={i} job={job} />
        ))}
      </Flex>

      {jobs.length > slicedJobs.length && (
        <Alert mt="1.25rem">
          <AlertIcon />
          <AlertTitle mr={2}>
            {pluralize(jobs.length - slicedJobs.length, "additional job")} are
            not shown.
          </AlertTitle>
        </Alert>
      )}
    </Flex>
  );
}

export default function JobQueue() {
  const { failedJobs, waitingJobs, currentJob, finishedJobs } = useContext(
    JobQueueContext
  );

  const listedJobs = [
    ...failedJobs,
    ...finishedJobs,
    ...(currentJob ? [currentJob] : []),
    ...waitingJobs,
  ];

  const hasJobs = !!waitingJobs.length || currentJob;

  return (
    <>
      <JobsCard jobs={listedJobs}>
        {hasJobs && (
          <>
            <Heading as="h3" p="0.75rem" fontSize="1.2rem" fontWeight="600">
              <>
                Processing{" "}
                {pluralize(waitingJobs.length + (currentJob ? 1 : 0), "job")}
                ... {!!failedJobs.length && <> ({failedJobs.length} failed)</>}
              </>
            </Heading>
            <Divider mb="1.25rem" />
          </>
        )}

        {!hasJobs && !failedJobs.length && (
          <Center minHeight="20rem">
            <Box opacity="0.3" mb="1.25rem">
              {/* <Ghost size={150} mood="excited" color="#E0E4E8" /> */}
              <span
                role="img"
                aria-label="sparkle"
                style={{ fontSize: "5rem" }}
              >
                ✨
              </span>
            </Box>
            <Text fontSize="1.2rem" fontWeight="600">
              All jobs are done, nothing more to do!
            </Text>
          </Center>
        )}
      </JobsCard>
    </>
  );
}
