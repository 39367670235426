import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Heading, IconButton, Input, Tooltip } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { PathCrumbs } from "../files/PathCrumbs";
import { ActiveWorkspaceContext } from "../providers/ActiveWorkspaceProvider";
import { folderResourceTypes } from "../utils/resourceUtils";
import { getNodeUrl } from "../utils/urlGetters";
import { humanize } from "../utils/utils";

export function SimpleHeaderNavbarContent(props) {
  const { text, ...rest } = props;
  return (
    <>
      <BackIconButton mr="0.75rem" />

      <Heading as="h1" fontSize="1.2rem" fontWeight="600" mr="0.5rem" {...rest}>
        {text}
      </Heading>
      <Box flexGrow={1} />
    </>
  );
}

export function RenameNodeInput(props) {
  const { nodeId } = props;
  const { fileTree, setNode, setBrowseModeOverride } = useContext(
    ActiveWorkspaceContext
  );

  const node = fileTree[nodeId];

  const onClose = () => setBrowseModeOverride(null);

  return (
    <Input
      autoFocus
      width={undefined}
      flexGrow={1}
      ref={(element) => element?.select()}
      defaultValue={node.name}
      placeholder={`Name this ${humanize(node.type)}...`}
      // onBlur={onClose}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          const name = event.target.value;
          if (name) setNode({ ...node, name });
          onClose();
        }
        if (event.key === "Escape") {
          onClose();
          event.stopPropagation();
        }
      }}
      mr="0.25rem"
    />
  );
}

export function PathCrumbsNav(props) {
  const { nodeId } = useParams();
  const { fileTree } = useContext(ActiveWorkspaceContext);

  const node = fileTree[nodeId];

  // TODO?
  if (!node) return <SimpleHeaderNavbarContent text="Not found" />;

  // const isRoot = node?.id === "$ROOT";

  // const parentNode = fileTree[node.parent];

  return <PathCrumbs interactive mr="0.5rem" flexGrow="1" flexShrink="1" />;
}

export function NewIconButton(props) {
  const { newThingName, ...rest } = props;
  const { workspaceId, nodeId } = useParams();
  const { fileTree } = useContext(ActiveWorkspaceContext);

  const node = fileTree[nodeId];
  if (!folderResourceTypes.includes(node?.type)) return null;

  return (
    <IconButton
      className="fade-in-slow"
      as={Link}
      variant="ghost"
      borderRadius="full"
      // size="lg"
      // colorScheme="blue"
      icon={<AddIcon />}
      mr="0.5rem"
      to={`/${workspaceId}/search/${encodeURIComponent(":new ")}`}
      onClick={() => {
        document.getElementById("app-search-input")?.focus();
      }}
      {...rest}
      title={"New" + (newThingName ? " " + newThingName : "...")}
    />
  );
}

export function BackIconButton(props) {
  const { workspaceId } = useParams();

  // const rememberedNodeId =
  //   readFromLocalStorage("folder_" + workspaceId) || "$ROOT";

  // const nodeId = fileTree[rememberedNodeId] ? rememberedNodeId : "$ROOT";

  return (
    <Tooltip label="Back to files">
      <IconButton
        as={Link}
        to={getNodeUrl(workspaceId, "$ROOT")}
        icon={<ArrowBackIcon />}
        {...props}
      />
    </Tooltip>
  );
}

// export function CalendarNavbar() {
//   return (
//     <Navbar>
//       <ButtonGroup variant="ghost" spacing={0} alignItems="center">
//         <Tooltip label="Show month">
//           <IconButton icon={<ArrowBackIcon />} />
//         </Tooltip>

//         <Crumb title="2021" interactive />
//         <CrumbSeparator />
//         <Crumb title="February" interactive>
//           02
//         </Crumb>
//         <CrumbSeparator />
//         <Crumb title="17" interactive isLast />
//       </ButtonGroup>
//       <Box flexGrow="1" />
//     </Navbar>
//   );
// }
