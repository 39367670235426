import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SuspenseWithPerf } from "reactfire";
import { ActiveWorkspaceContext } from "../../providers/ActiveWorkspaceProvider";
import {
  Center,
  CenteredNotFoundMessage,
  CenteredSpinner,
} from "../../utils/utils";
import ActivityEditor from "./activityEditor/ActivityEditor";
import { NoteEditor } from "./NoteEditor";
import { FileBrowser } from "./shared/FileBrowser";
import { TimedRoutineEditor } from "./TimedRoutineEditor";
import { UploadedFileEditor } from "./UploadedFileEditor";

export function FileViewer() {
  const { nodeId } = useParams();
  const { timestampNode, fileTree } = useContext(ActiveWorkspaceContext);

  const node = fileTree[nodeId];

  useEffect(() => {
    timestampNode(nodeId);
  }, [timestampNode, nodeId]);

  if (!node) return <CenteredNotFoundMessage />;

  const SpecificEditor =
    {
      activity: ActivityEditor,
      note: NoteEditor,
      timed_routine: TimedRoutineEditor,
      uploaded_file: UploadedFileEditor,
      folder: FolderEditor,
    }[node.type] || UnknownEditorError;

  return (
    <SuspenseWithPerf
      fallback={<CenteredSpinner />}
      traceId={"suspense-FileViewer"}
    >
      <Box
        key={node.id}
        display="flex"
        className="FileViewer"
        flexGrow="1"
        // overflow="auto"
        flexDirection="column"
      >
        <SpecificEditor node={node} />
      </Box>
    </SuspenseWithPerf>
  );
}

function FolderEditor(props) {
  const { node } = props;

  return (
    <Flex
      flexGrow="1"
      flexDirection="column"
      m="0.75rem"
      className="FolderEditor"
    >
      <FileBrowser nodeId={node.id} />
    </Flex>
  );
}

function UnknownEditorError(props) {
  const { editor } = props;

  return (
    <Center fontWeight="600">
      <Text>Unknown editor type "{editor?.type}" not supported.</Text>
    </Center>
  );
}
