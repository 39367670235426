import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { JobQueueContext } from "../providers/JobQueueProvider";

export function JobProgressIndicator() {
  const { workspaceId } = useParams();
  const url = `/${workspaceId}`;

  const { currentJob } = useContext(JobQueueContext);

  if (!currentJob) return null;

  return (
    <Flex
      flexDirection="column"
      // overflowY="auto"
      ml="1.25rem"
      // mb="-0.75rem"
      // mr="-1.25rem"
    >
      {/* <Divider /> */}

      <Tooltip hasArrow placement="bottom" label={currentJob.label}>
        <Button
          as={Link}
          to={url + "/jobs"}
          display="flex"
          flexDir="column"
          // height="1.5rem"
          // alignItems="stretch"
          // textAlign="left"
          fontWeight="normal"
          variant="ghost"
          colorScheme="blue"
          // padding="0"
          mr="0.5rem"
          px="0"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex overflow="hidden" textOverflow="ellipsis" alignItems="center">
            {/* {currentJob.progress === "indeterminate" && ( */}
            <CircularProgress
              isIndeterminate={currentJob.progress === "indeterminate"}
              value={
                currentJob.progress === "indeterminate"
                  ? 0
                  : (currentJob.progress || 0) * 100
              }
              size="2rem"
            >
              {currentJob.progress !== "indeterminate" && (
                <CircularProgressLabel>
                  {Math.floor((currentJob.progress || 0) * 100)}%
                </CircularProgressLabel>
              )}
            </CircularProgress>
          </Flex>

          {/* {typeof currentJob.progress === "number" && (
          <Progress
            hasStripe
            isAnimated
            // color="green"
            size="md"
            value={currentJob.progress * 100}
          />
        )} */}
        </Button>
      </Tooltip>
    </Flex>
  );
}
