import { Box, Flex, Image, Text } from "@chakra-ui/react";
import PinchZoomPan from "jbe-react-responsive-pinch-zoom-pan";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useStorage, useStorageDownloadURL } from "reactfire";
import { Center } from "../../utils/utils";

export function useUploadedFileUrl(nodeId) {
  const { workspaceId } = useParams();

  const storage = useStorage();

  const storageRef = useMemo(
    () => storage.ref(["/workspaces", workspaceId, "files", nodeId].join("/")),
    [nodeId, storage, workspaceId]
  );

  return useStorageDownloadURL(storageRef);
}

export function UploadedFileEditor(props) {
  const { node } = props;

  const downloadURL = useUploadedFileUrl(node.id);

  if (!downloadURL) return null;

  return (
    <>
      {/* <FilesNavbarItems hideWhenInactive /> */}
      <Flex
        className="fade-in-slow"
        flexDirection="column"
        flexGrow="1"
        flexShrink="1"
        // backgroundColor="black"
        backgroundColor="rgba(0,0,0, 0.9)"
        // color="#eee"
        justifyContent="center"
        alignItems="center"
        // height="90vh"
        overflow="auto"
      >
        <UploadedFileGenericViewer node={node} downloadURL={downloadURL} />
      </Flex>
    </>
  );
}

function UploadedFileGenericViewer(props) {
  const { downloadURL, node } = props;

  if ((node.contentType || "").startsWith("audio/")) {
    return (
      <Box
        as="audio"
        key={downloadURL}
        controls
        autoPlay
        alignSelf="center"
        w="100%"
        maxW="60rem"
        px="1.25rem"
      >
        <source src={downloadURL} type={node.contentType} />
        Your browser does not support embedded audio.
      </Box>
    );
  }

  if ((node.contentType || "").startsWith("image/")) {
    return (
      <Flex
        alignSelf="stretch"
        flexDirection="column"
        flexGrow={1}
        height="100vh"
      >
        <PinchZoomPan
          position="center"
          zoomButtons={false}
          doubleTapBehavior="zoom"
          maxScale={4}
        >
          <Image
            src={downloadURL}
            // maxH="50vh"
            // flexGrow="1"
            objectFit="contain"
            flexShrink="1"
            // maxHeight="100vh"
            // maxWidth="100vw"
            boxShadow="0px 0px 40px 0px rgba(0, 0, 0, 0.1)"
          />
        </PinchZoomPan>
      </Flex>
    );
  }

  if ((node.contentType || "").startsWith("video/")) {
    return (
      <Box
        as="video"
        controls
        autoPlay
        flexShrink="1"
        maxHeight="100vh"
        maxWidth="100vw"
      >
        <source src={downloadURL} type={node.contentType} />
        Your browser does not support embedded videos.
      </Box>
    );
  }

  return (
    <Center>
      <Text fontSize="1.3rem" color="white">
        No viewer available for this content type. {node.contentType}
      </Text>
    </Center>
  );
}
