import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-use";
import { useFirestore, useUser } from "reactfire";
import { getSearchUrl } from "../utils/urlGetters";
import { CenteredNotFoundMessage, FullScreenSpinner } from "../utils/utils";
import {
  useController,
  WorkspaceDataController,
} from "./WorkspaceDataController";
import { WorkspacesContext } from "./WorkspacesProvider";

export const ActiveWorkspaceContext = createContext();

/*
  Describes capabilities of a listing of nodes. Can contain config, but currently only
  uses its presence to indicate a listing.

*/
export const NodeListingContext = createContext();

export const emptyFileTree = {
  $ROOT: {
    id: "$ROOT",
    name: "$ROOT",
    type: "folder",
    children: [],
  },
};

export default function ActiveWorkspaceRefresher(props) {
  const { workspaceId } = useParams();

  return <ActiveWorkspaceProvider key={workspaceId} {...props} />;
}

function ActiveWorkspaceProvider(props) {
  const { children } = props;
  const { uid } = useUser();
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const db = useFirestore();
  const workspaces = useContext(WorkspacesContext);

  const {
    ready,
    error,
    data: activeWorkspaceDataData,
    ref: activeWorkspaceDataRef,
    controller: activeWorkspaceDataController,
  } = useController(WorkspaceDataController, workspaceId);

  const activeWorkspaceDoc =
    workspaces.docs.find((doc) => doc.id === workspaceId) || workspaces.docs[0];

  const activeWorkspace = useMemo(
    () => activeWorkspaceDoc && activeWorkspaceDoc.data(),
    [activeWorkspaceDoc]
  );

  const activeWorkspaceRef = useMemo(
    () => db.collection("workspaces").doc(workspaceId),
    [db, workspaceId]
  );

  useEffect(() => {
    localStorage.setItem("lastWorkspaceId-" + uid, workspaceId);
  }, [workspaceId, uid]);

  useHotkeys(workspaceId);

  const [selectedNodeIds, setSelectedNodeIds] = useState(null);

  const toggleNodeSelected = useCallback(
    (nodeId) => {
      setSelectedNodeIds((old) => {
        // if deselecting last item, then leave select mode
        if (old?.[nodeId] && Object.values(old).filter((v) => v).length === 1)
          return null;

        // else toggle item
        return { ...(old || {}), [nodeId]: !(old || {})[nodeId] };
      });
    },
    [setSelectedNodeIds]
  );

  const [browseModeOverride, setBrowseModeOverride] = useState(null);

  useEffect(() => {
    setBrowseModeOverride(null);
    setSelectedNodeIds(null);
  }, [pathname]);

  const { fileTree } = activeWorkspaceDataData || {};

  const value = useMemo(
    () => ({
      ...(activeWorkspaceDataController || {}),
      activeWorkspace,
      activeWorkspaceRef,
      activeWorkspaceDataData,
      activeWorkspaceDataRef,
      activeWorkspaceDataController, // TODO: remove
      fileTree,
      selectedNodeIds,
      setSelectedNodeIds,
      toggleNodeSelected,
      browseModeOverride,
      setBrowseModeOverride,
    }),
    [
      activeWorkspace,
      activeWorkspaceRef,
      activeWorkspaceDataData,
      activeWorkspaceDataRef,
      activeWorkspaceDataController,
      fileTree,
      selectedNodeIds,
      setSelectedNodeIds,
      toggleNodeSelected,
      browseModeOverride,
      setBrowseModeOverride,
    ]
  );

  if (!ready) return <FullScreenSpinner text="Opening..." />;
  if (error) return <CenteredNotFoundMessage height="100vh" />;
  if (!fileTree) return <FullScreenSpinner text="No file tree..." />; // TODO

  return (
    <ActiveWorkspaceContext.Provider value={value}>
      {children}
    </ActiveWorkspaceContext.Provider>
  );
}

function useHotkeys(workspaceId) {
  const history = useHistory();
  const { pathname } = useLocation();
  const searchIsActive = pathname.startsWith(getSearchUrl(workspaceId));

  useEffect(() => {
    function handleKeyDown(event) {
      const element = event.target;

      if (element.nodeName === "INPUT" || element.nodeName === "TEXTAREA")
        return;

      if (
        (event.key === "S" || event.key === "F" || event.key === "/") &&
        event.shiftKey
      ) {
        if (!searchIsActive) history.push(getSearchUrl(workspaceId));
        document.getElementById("app-search-input")?.focus();
        event.preventDefault();
      }

      if ((event.key === "C" && event.shiftKey) || event.key === ":") {
        history.push(getSearchUrl(workspaceId, ":"));
        document.getElementById("app-search-input")?.focus();
        event.preventDefault();
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [workspaceId, history, searchIsActive]);
}
