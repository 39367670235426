import { Button, ButtonGroup, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import FormProvider, { InputControl } from "../../../providers/FormProvider";

export default function ActivityContentCard() {
  return (
    <FormProvider
      alwaysUnlocked
      overflow="auto"
      p="1.25rem"
      // unsavedChangesLockId="ActivityContentCard"
      initialValues={{}}
      onSave={(val) => {
        console.log("save", val);
      }}
    >
      <Text mb="2rem">
        TODO: Open new node menu when clicking add content. Support file uploads
        in new node menu. Progress tracking can support adding plain number
        inputs, as well as interval routines, with metronome etc.
      </Text>

      <InputControl
        textArea
        id="description"
        label="Description (optional)"
        help="Enter a description that explains this activity"
        maxLength="160"
      />

      <ButtonGroup mb="1.25rem">
        <Button icon={<AddIcon />}>Add content</Button>

        <Button icon={<AddIcon />} my="2rem">
          Add progress tracking
        </Button>
      </ButtonGroup>
    </FormProvider>
  );
}
