import metronomeClick from "./assets/metronome_click.mp3";

let audioContext;
let tickBuffer;
let sampleSource;
let currentBpm = 0;

export async function setMetronomeBpm(bpm) {
  if (bpm === currentBpm) return;
  currentBpm = bpm;

  if (!audioContext) audioContext = new AudioContext();

  if (!tickBuffer) {
    const response = await fetch(metronomeClick);
    const arrayBuffer = await response.arrayBuffer();
    const onlyTickBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // pad with 10s silence at end, so we can use loopEnd to control bpm.

    const targetLength =
      onlyTickBuffer.sampleRate * onlyTickBuffer.numberOfChannels * 10;

    tickBuffer = new AudioBuffer({
      length: targetLength,
      numberOfChannels: onlyTickBuffer.numberOfChannels,
      sampleRate: onlyTickBuffer.sampleRate,
    });

    for (let i = 0; i < onlyTickBuffer.numberOfChannels; i++) {
      onlyTickBuffer.copyFromChannel(tickBuffer.getChannelData(i), i, 0);
    }

    sampleSource = audioContext.createBufferSource();
    sampleSource.loop = true;

    sampleSource.buffer = tickBuffer;

    sampleSource.start();
  }

  // if (sampleSource) {
  //   sampleSource.stop();
  //   sampleSource.disconnect();
  //   sampleSource = null;
  // }

  if (!bpm) {
    sampleSource.disconnect();
    return;
  }

  const tickLength = 60 / bpm;

  sampleSource.loopEnd = tickLength;

  sampleSource.connect(audioContext.destination);
}

// setMetronomeBpm(50);
