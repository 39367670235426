import { Button, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ActiveWorkspaceContext } from "../../providers/ActiveWorkspaceProvider";
import { getNodeUrl } from "../../utils/urlGetters";
import {
  ActionDialog,
  getNodeName,
  humanize,
  pluralize,
} from "../../utils/utils";

export function DeleteNodeDialog() {
  const { workspaceId } = useParams();
  const history = useHistory();
  const showToast = useToast();

  const {
    fileTree,
    trashNode,
    browseModeOverride,
    setBrowseModeOverride,
  } = useContext(ActiveWorkspaceContext);

  const [isWorking, setIsWorking] = useState(false);

  const { type, ids } = browseModeOverride || {};

  if (type === "delete") {
    if (ids.length < 1) {
      console.log(ids);
      throw new Error("Missing ids");
    }
  }

  const deletedThingName =
    type === "delete" &&
    (ids.length === 1
      ? fileTree[ids[0]] && getNodeName(fileTree[ids[0]])
      : pluralize(ids.length, "file"));

  async function handleSubmit() {
    setIsWorking(true);
    await ids.reduce(async (lastPromise, nodeId) => {
      await lastPromise;
      const node = fileTree[nodeId];
      if (!node) {
        throw new Error("DeleteNodeDialog: invalid node id: " + nodeId);
      }

      await trashNode(node.id);
    }, Promise.resolve());

    setIsWorking(false);
    setBrowseModeOverride(null);
    showToast({
      position: "bottom-left",
      title: `${humanize(deletedThingName)} deleted`,
      status: "success",
      isClosable: true,
    });
    // TODO:
    history.replace(getNodeUrl(workspaceId, fileTree[ids[0]].parent));
  }

  return (
    <ActionDialog
      isOpen={type === "delete"}
      title={"Delete file"}
      isWorking={isWorking}
      actionButton={
        <Button type="submit" isLoading={isWorking} colorScheme="red" ml={3}>
          Delete
        </Button>
      }
      onClose={() => setBrowseModeOverride(null)}
      onSubmit={handleSubmit}
    >
      {isWorking ? (
        <Text mb="1rem">Deleting...</Text>
      ) : (
        <Text mb="1rem">
          Are you sure you want to delete <em>{deletedThingName}</em>?{" "}
        </Text>
      )}

      {/* <Flex mb="0.5rem">
        <Box fontWeight="600" mr="0.5rem">
          Location:
        </Box>

        <PathCrumbs targetNodeId={nodeId} />
      </Flex> */}

      {/* {!!node?.children?.length && (
        <Flex mb="0.5rem">
          <Box fontWeight="600" mr="0.5rem">
            Contents:
          </Box>

          <Box>{node && pluralize(node.children?.length, "file")}</Box>
        </Flex>
      )} */}
    </ActionDialog>
  );
}
