import React, { createContext, useState, useCallback, useContext } from "react";
import { useMemo } from "react";
import { datacubeDraggableMimeType } from "../utils/resourceUtils";

export const DragAndDropStateContext = createContext();

export default function DragAndDropStateProvider(props) {
  const { children } = props;

  const [dragAndDrop, setDragAndDrop] = useState({
    from: null,
    to: null,
    left: null, // most recently left item
  });

  const dragTo =
    !dragAndDrop.left || dragAndDrop.left !== dragAndDrop.to
      ? dragAndDrop.to
      : null;

  const value = useMemo(
    () => ({ dragAndDrop, setDragAndDrop, dragFrom: dragAndDrop.from, dragTo }),
    [dragAndDrop, setDragAndDrop, dragTo]
  );

  return (
    <DragAndDropStateContext.Provider value={value}>
      {children}
    </DragAndDropStateContext.Provider>
  );
}

export function useDropTarget(props) {
  const { ref, dragItem, validateDrag, onDragAndDrop } = props;
  const { dragAndDrop, setDragAndDrop } = useContext(DragAndDropStateContext);

  const stillOnTarget = dragAndDrop.to && dragAndDrop.to !== dragAndDrop.left;

  const passedValidation = validateDrag(dragAndDrop.from || dragItem); // if from is null, then the drag just started and the source is the current item

  const isValidTarget = useCallback(
    (event) => {
      const { dataTransfer } = event;
      return (
        ref.current &&
        !ref.current.contains(event.relatedTarget) &&
        dataTransfer.types.includes(datacubeDraggableMimeType) &&
        passedValidation
      );
    },
    [passedValidation, ref]
  );

  const onDragEnter = useCallback(
    (event) => {
      if (!isValidTarget(event)) return;
      event.stopPropagation();
      event.preventDefault();

      // console.log("dragEnter", dragAndDrop.from);

      setDragAndDrop((old) => ({
        ...old,
        to: dragItem,
        left: old.left && old.left === dragItem ? null : old.left,
      }));
    },
    [dragItem, setDragAndDrop, isValidTarget]
  );

  const onDragLeave = useCallback(
    (event) => {
      if (!isValidTarget(event)) return;
      event.stopPropagation();
      event.preventDefault();

      // event.persist();
      // console.log("dragLeave", event.target, event);

      setDragAndDrop((old) => ({
        ...old,
        left: dragItem,
      }));
    },
    [dragItem, setDragAndDrop, isValidTarget]
  );

  const onDrop = useCallback(
    async (event) => {
      if (!stillOnTarget) return;
      if (!isValidTarget(event)) return;

      event.stopPropagation();
      event.preventDefault();

      // foundItem.getAsString(console.log);

      onDragAndDrop(dragAndDrop.from, dragAndDrop.to);

      setDragAndDrop((v) => ({
        from: null,
        to: null,
        left: null,
      }));
    },
    [dragAndDrop, setDragAndDrop, onDragAndDrop, stillOnTarget, isValidTarget]
  );

  const onDragEnd = useCallback(() => {
    // console.log("drag end");
    setDragAndDrop((v) => ({
      from: null,
      to: null,
      left: false,
    }));
  }, [setDragAndDrop]);

  const onDragOver = useCallback(
    (event) => {
      if (!isValidTarget(event)) return;
      event.stopPropagation();
      event.preventDefault();
    },
    [isValidTarget]
  );

  return useMemo(() => {
    return {
      onDragEnter,
      onDragLeave,
      onDrop,
      onDragEnd,
      onDragOver,
    };
  }, [onDragEnter, onDragLeave, onDrop, onDragEnd, onDragOver]);
}

export function useDraggable(props) {
  const { dragItem } = props;

  const { setDragAndDrop } = useContext(DragAndDropStateContext);

  const onDragStart = useCallback(
    (event) => {
      // console.log("dragStart", i);
      event.target.blur();

      setDragAndDrop({ from: dragItem });

      const img = new window.Image();
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      // event.dataTransfer.setDragImage(img, 0, 0);
      event.dataTransfer.setData(datacubeDraggableMimeType, "Not used"); // Firefox needs some data to work
    },
    [setDragAndDrop, dragItem]
  );

  return useMemo(() => {
    return {
      onDragStart,
      draggable: true,
    };
  }, [onDragStart]);
}
