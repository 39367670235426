import { AddIcon, CheckIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Tag,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Window } from "../ui/Window";
import CalendarHeader from "./CalendarHeader";

function Marks() {
  return (
    <HStack>
      {["blue", "red", "yellow", "green", "orange", "purple"].map(
        (colorScheme) => {
          return (
            <IconButton
              key={colorScheme}
              icon={<SmallCloseIcon />}
              size="sm"
              borderRadius="1000px"
              colorScheme={colorScheme}
              fontSize={0}
              _hover={{
                fontSize: "inherit",
              }}
            />
          );
        }
      )}

      <Button leftIcon={<AddIcon />} size="sm" borderRadius="1000px">
        Add mark
      </Button>
    </HStack>
  );
}

export default function CalendarPage() {
  return (
    <>
      {/* <CalendarNavbar /> */}

      <Window>
        <Flex m="1.25rem">
          <CalendarHeader mr="1.25rem" flexGrow="1" />

          <Box>
            <Marks />
          </Box>
        </Flex>

        <Heading as="h1" fontSize="xl" mx="1.25rem">
          Todays tasks
        </Heading>

        <ButtonGroup m="1.25rem" size="sm" colorScheme="orange">
          <Button>Only 3 rudiments</Button>
          <Button>Paradiddle applications</Button>
          <Button>Verbs 4</Button>
          <Button>5k</Button>
          <Button colorScheme="gray">Home page</Button>
          <Button colorScheme="gray">Prepare sale</Button>
          <Button colorScheme="gray">Buy shoes</Button>
        </ButtonGroup>

        <Divider />

        <HStack alignItems="center" m="1.25rem" mb="0">
          <Heading as="h1" fontSize="xl" mr="0.75rem">
            Notes
          </Heading>

          <Button leftIcon={<AddIcon />} size="sm" borderRadius="1000px">
            New note
          </Button>
        </HStack>

        <VStack m="1.25rem" alignItems="flex-start">
          <Flex>
            <Button variant="link" colorScheme="blue">
              Some thoughts about weird stuff
            </Button>
            <Tag colorScheme="orange" ml="0.5rem">
              Weird
            </Tag>
          </Flex>

          <Flex>
            <Button variant="link" colorScheme="blue">
              I farted again
            </Button>
            <Tag ml="0.5rem" colorScheme="orange">
              Poop
            </Tag>
          </Flex>

          <Flex>
            <Button variant="link" colorScheme="blue">
              Why do you poop in your pants
            </Button>
            <Tag ml="0.5rem" colorScheme="orange">
              Poop
            </Tag>
            <Tag ml="0.5rem" colorScheme="orange">
              Weird
            </Tag>
          </Flex>
        </VStack>

        <Divider />

        <Heading as="h1" fontSize="xl" m="1.25rem" mb="0">
          Completed tasks
        </Heading>

        <ButtonGroup
          m="1.25rem"
          size="sm"
          colorScheme="green"
          variant="link"
          spacing="1.25rem"
        >
          <Button leftIcon={<CheckIcon />}>LW intermediate</Button>
          <Button leftIcon={<CheckIcon />}>Duo</Button>
          <Button leftIcon={<CheckIcon />}>Sit-ups</Button>
          <Button leftIcon={<CheckIcon />}>Buy socks</Button>
        </ButtonGroup>

        <ButtonGroup
          m="1.25rem"
          mt={0}
          size="sm"
          spacing="1.25rem"
          variant="link"
        >
          <Button variant="solid" colorScheme="red">
            1 missed
          </Button>
          <Button colorScheme="gray">4 postponed</Button>
        </ButtonGroup>

        <HStack m="1.25rem"></HStack>
      </Window>
    </>
  );
}
