import React, { useMemo, createContext, useState, useEffect } from "react";
import { useLocation } from "react-router";

export const ContextMenuContext = createContext();

export default function ContextMenuProvider(props) {
  const { children } = props;

  const [contextMenu, setContextMenu] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    function onClick(event) {
      // if (!menuRef.current?.contains(event.target)) {
      // console.log(menuRef.current, event.target);
      // debugger;
      setContextMenu(null);
      // setTimeout(() => setContextMenu(null), 1);
      // }
    }

    document.body.addEventListener("click", onClick);
    return () => document.body.removeEventListener("click", onClick);
  });

  useEffect(() => {
    setContextMenu(null);
  }, [pathname]);

  const value = useMemo(
    () => ({
      contextMenu,
      setContextMenu,
    }),
    [contextMenu, setContextMenu]
  );

  return (
    <ContextMenuContext.Provider value={value}>
      {children}
    </ContextMenuContext.Provider>
  );
}
