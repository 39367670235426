import { Box, Flex, Stack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ActiveWorkspaceContext } from "../../../providers/ActiveWorkspaceProvider";
import FormProvider, {
  FormContext,
  FormHeading,
  InputControl,
  Option,
  SelectControl,
  SwitchControl,
} from "../../../providers/FormProvider";

export default function ActivitySettingsCard(props) {
  const { node } = props;

  const { setNode } = useContext(ActiveWorkspaceContext);

  return (
    <FormProvider
      alwaysUnlocked
      // unsavedChangesLockId="ActivitySettingsCard"
      initialValues={{
        name: node.name,
        timeoutUnit: "days",
        repetitionCooldownUnit: "days",
        skipScope: "activity",
        ...node.activitySettings,
      }}
      onSave={({ name }, event) => {
        const data = new FormData(event.target);

        const activitySettings = {};

        for (let [name, rawValue] of data.entries()) {
          const isBoolean =
            name.startsWith("enable") || name.startsWith("requires");
          const value = isBoolean ? rawValue === "true" : rawValue;

          activitySettings[name] = value;
        }

        setNode({
          ...node,
          name,
          activitySettings,
        });
      }}
      overflow="auto"
      p="1.25rem"
    >
      {/* <SettingsSubHeader>
            Here you can edit the rules and behaviour of this activity.
          </SettingsSubHeader> */}

      <SettingsGroup>
        {/* <FormHeading>Appearance</FormHeading> */}

        <InputControl
          id="name"
          label="Activity name"
          help="Enter a name to identify this activity"
          isRequired
          maxLength="80"
          size="lg"
        />
      </SettingsGroup>

      <SubactivityFields />
      <MasterSwitches />
    </FormProvider>
  );
}

function MasterSwitches() {
  const {
    fields: {
      requiresContentCompletion,
      // enableManualCompletion,
      // enableTimeoutAfterCompletion,
      timeoutMode,
      timeoutNumber,
      numberOfRepetitionsRequired,
      enableSkip,
      enableSubActivities,
      isIndependentFromSubActivities,
    },
  } = useContext(FormContext);

  return (
    <>
      <SettingsGroup>
        <FormHeading>This activity</FormHeading>

        {!enableSubActivities && (
          <>
            <Flex alignItems="baseline">
              <SwitchControl
                id="requiresContentCompletion"
                help={
                  requiresContentCompletion &&
                  "Configure content in the content tab."
                }
              >
                Requires completing content
              </SwitchControl>
            </Flex>
          </>
        )}

        {(!enableSubActivities || isIndependentFromSubActivities) && (
          <>
            {/* {(enableSubActivities || requiresContentCompletion) && (
              <SwitchControl id="enableManualCompletion">
                Requires pressing a button to complete
              </SwitchControl>
            )}

            {enableManualCompletion && (
              <Stack
                isInline
                spacing="0.75rem"
                //  ml="3rem"
              >
                <InputControl
                  id="completeButtonText"
                  label="Button text (optional)"
                  placeholder="Done"
                  help="Enter text to show on the button used to complete the activity"
                />

                <InputControl
                  id="confirmLabelText"
                  label="Label next to button (optional)"
                  placeholder="Click to complete:"
                  help="Enter text to be displayed next to the completion button"
                />
              </Stack>
            )} */}

            <SwitchControl id="enableRepetitionWhileCompleted">
              Can be completed again while already completed
            </SwitchControl>
          </>
        )}

        <SwitchControl id="enableSkip">Can be skipped</SwitchControl>
      </SettingsGroup>

      {(!enableSubActivities || isIndependentFromSubActivities) && (
        <SettingsGroup>
          <FormHeading>Repetitions and validity period</FormHeading>

          {!isIndependentFromSubActivities && (
            <Stack isInline spacing="0.75rem">
              <InputControl
                id="numberOfRequiredRepetitions"
                type="number"
                label="Required number of repetitions (optional)"
                help={
                  "Enter the number of times this activity must be repeated before it becomes valid"
                }
                placeholder="1"
                maxLength="5"
              />

              {numberOfRepetitionsRequired && (
                <TimeDurationFields
                  idBase="repetitionCooldown"
                  label="Cool-down between repetitions (optional)"
                  placeholder="No cooldown"
                />
              )}
            </Stack>
          )}

          <SelectControl id="timeoutMode" label="Timeout">
            <Option value="no_timeout">
              No timeout. Permanent once completed.
            </Option>
            <Option value="spaced_repetition">
              Spaced repetition (memorization)
            </Option>
            <Option value="fixed">Fixed timeout</Option>
          </SelectControl>

          {timeoutMode === "fixed" && (
            <Flex>
              <TimeDurationFields
                idBase="timeout"
                label="Times out after (optional)"
                placeholder="Never"
                help="Enter an amount of time after which this activity times out and must be completed again"
                // ml="3rem"
              />

              {timeoutNumber && enableSkip && (
                <SelectControl
                  id="skipScope"
                  label="Skip power"
                  // help="Select the type of condition that will be used to complete this activity"
                  // width="10rem"
                >
                  <Option value="repetition">
                    Times out just like a completion
                  </Option>
                  <Option value="activity">
                    Skips the activity permanently
                  </Option>
                  <Option value="userChoice">User's choice</Option>
                </SelectControl>
              )}
            </Flex>
          )}
        </SettingsGroup>
      )}
    </>
  );
}

function SubactivityFields() {
  const {
    fields: { enableSubActivities, isIndependentFromSubActivities },
  } = useContext(FormContext);

  return (
    <SettingsGroup>
      {/* <SelectControl
          id="subActivityMode"
          label="Sub-activities"
          help="Select if sub-activities are required to complete this activity"
          width="20rem"
          >
          <Option value="none">No sub-activities</Option>
          <Option value="all_of">Green as long as children are green</Option>
          <Option value="some_of">
          Green as long as some children are green
          </Option>
        </SelectControl> */}

      <FormHeading>Sub-activities</FormHeading>

      <SwitchControl id="enableSubActivities">
        Requires sub-activities
      </SwitchControl>

      {enableSubActivities && (
        <Box ml="3rem">
          <InputControl
            id="numberOfSubActivitiesRequired"
            type="number"
            label="Required number of completed sub-activities (optional)"
            placeholder="All subactivites"
            help="Enter the number of different sub-activities that must be completed in order for this activity to be completed. Leave blank to require all sub-activities."
            max="1000"
          />

          <SelectControl
            id={"subActivityPrioritization"}
            label="Prioritization of sub-activities"
            // width="10rem"
          >
            <Option value="earliestTimeout">Earliest timeout first</Option>
            <Option value="earliestCompletion">
              Earliest completion first
            </Option>
          </SelectControl>

          <SwitchControl id="enforvePrioritizedOrder">
            Enforce prioritized order
          </SwitchControl>

          <SwitchControl id="isIndependentFromSubActivities">
            Remains valid even if required sub-activities lose validity
          </SwitchControl>

          {isIndependentFromSubActivities && (
            <SelectControl
              id={"subActivityCompletionMode"}
              label="Sub-activity requirement mode"
              // width="10rem"
            >
              <Option value="mustBeCompletedNow">
                The required number of sub-activities must be completed
                explicitly for this activity
              </Option>
              <Option value="allowAlreadyCompleted">
                Already completed sub-activities count towards required number
              </Option>
            </SelectControl>
          )}
        </Box>
      )}
    </SettingsGroup>
  );
}

function TimeDurationFields(props) {
  const { idBase, label, placeholder, help, ...rest } = props;

  return (
    <Stack isInline spacing="0.75rem" {...rest}>
      <InputControl
        id={idBase + "Number"}
        type="number"
        label={label}
        help={help}
        placeholder={placeholder}
        maxLength="5"
      />

      <SelectControl
        id={idBase + "Unit"}
        label="Unit"
        // width="10rem"
      >
        <Option value="minutes">Minutes</Option>
        <Option value="hours">Hours</Option>
        <Option value="days">Days</Option>
        <Option value="months">Months</Option>
        <Option value="months">Years</Option>
      </SelectControl>
    </Stack>
  );
}

function SettingsGroup(props) {
  const { children, ...rest } = props;

  return (
    <Box mb="2rem" {...rest}>
      {children}
    </Box>
  );
}

// function FlexLine(props) {
//   const { children, ...rest } = props;

//   return (
//     <Flex alignItems="baseline" justifyContent="space-between" {...rest}>
//       {children}
//     </Flex>
//   );
// }
