import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import hash from "hash-sum";
import { Masonry } from "masonic";
import React, { useCallback, useContext, useMemo } from "react";
import { MdCloudUpload } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useStorage } from "reactfire";
import {
  ActiveWorkspaceContext,
  NodeListingContext,
} from "../../../providers/ActiveWorkspaceProvider";
import { DeviceContext } from "../../../providers/DeviceProvider";
import { JobQueueContext } from "../../../providers/JobQueueProvider";
import FileDropTarget from "../../../ui/FileDropTarget";
import { folderResourceTypes } from "../../../utils/resourceUtils";
import {
  Card,
  CenteredNotFoundMessage,
  getNodeName,
  humanize,
  pluralize,
  randomId,
  throttle,
} from "../../../utils/utils";
import { DraggableNodeItemButton } from "../../DraggableNodeItemButton";

const nodeNameCompare = (a, b) =>
  a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());

const folderCompare = (a, b, nodeCompare = nodeNameCompare) => {
  if (a.type !== b.type)
    return (
      folderResourceTypes.indexOf(a.type) - folderResourceTypes.indexOf(b.type)
    );
  else return nodeCompare(a, b);
};

export function sortNodesIntoTiles(nodes, nodeCompare = nodeNameCompare) {
  const folders = [];
  const files = [];
  const images = [];

  // group nodes:

  nodes.forEach((node) => {
    if (node.contentType?.startsWith("image/")) images.push(node);
    else if (folderResourceTypes.includes(node.type)) folders.push(node);
    else files.push(node);
  });

  // sort each group:

  folders.sort(folderCompare);
  files.sort(nodeCompare);
  images.sort(nodeCompare);

  const tiles = [];
  let count = 0;

  // Pack sorted tiles in correct order, adding indices:

  if (folders.length) {
    tiles.push({
      tileType: "files",
      nodes: folders,
      indexOffset: count,
    });

    count += folders.length;
  }

  if (files.length) {
    tiles.push({
      tileType: "files",
      nodes: files,
      indexOffset: count,
    });

    count += files.length;
  }

  images.forEach((node) => {
    tiles.push({
      tileType: "image",
      node,
      indexOffset: count++,
    });
  });

  return tiles;
}

export function FileBrowser(props) {
  const { nodeId } = props;
  const { fileTree } = useContext(ActiveWorkspaceContext);

  const { mouseDetected } = useContext(DeviceContext);

  const node = fileTree[nodeId];

  const children = useMemo(
    () => node?.children?.map((nodeId) => fileTree[nodeId]),
    [node, fileTree]
  );

  const masonryKey = useMemo(() => hash(node?.children), [node]);
  const tiles = useMemo(
    () => children && sortNodesIntoTiles(children),
    [children]
  );

  if (!node) return <CenteredNotFoundMessage />;

  return (
    <NodeListingContext.Provider value={{}}>
      <FileViewerDropHandler>
        {node.children?.length ? (
          <GenericMasonry key={masonryKey} tiles={tiles} />
        ) : (
          <Text color="gray.500" ml="1.25rem" mt="1.25rem">
            This {humanize(node.type)} is empty.
          </Text>
        )}

        {mouseDetected && (
          <Flex m="0.75rem" opacity="0.5" alignItems="center">
            <Box size="1.7rem" as={MdCloudUpload} opacity={0.5} mr="0.75rem" />
            <Text>Drop files to upload.</Text>
          </Flex>
        )}
      </FileViewerDropHandler>
    </NodeListingContext.Provider>
  );
}

export function GenericMasonry(props) {
  const { tiles } = props;

  return (
    <Masonry
      columnWidth={300}
      columnGutter={16}
      items={tiles}
      render={NodeMasonryCard}
      tabIndex={null}
    />
  );
}

const NodeMasonryCard = (props) => {
  const {
    // index,
    width,
    data: { tileType, node, nodes, indexOffset },
  } = props;

  if (tileType === "image") {
    return (
      <DraggableNodeItemButton
        // className={"fade-in-slow"}
        key={node.id}
        node={node}
        width={width}
        index={indexOffset}
      />
    );
  } else {
    return (
      <Card py="0.5rem">
        {nodes.map((groupNode, i) => (
          <DraggableNodeItemButton
            key={groupNode.id}
            node={groupNode}
            index={indexOffset + i}
          />
        ))}
      </Card>
    );
  }
};

function FileViewerDropHandler(props) {
  const { children } = props;

  const storage = useStorage();
  const { workspaceId, nodeId } = useParams();
  const showToast = useToast();
  const { pushJob } = useContext(JobQueueContext);
  const { setNode, fileTree } = useContext(ActiveWorkspaceContext);

  const node = fileTree[nodeId];

  const onDropFiles = useCallback(
    async (dataTransfer) => {
      // Maps to webkit entries. The data transfer objects exires along with the
      // call stack because of browser security, but the webkit entries will still
      // be available when the job runs.
      const entries = getDroppedFileEntries(dataTransfer.items);

      pushJob(
        "Process dropped files",
        async (onProgress, onSuccess, onError) => {
          const {
            error,
            nodes,
            rootNodeIds,
            fileCount,
            folderCount,
            totalSize,
          } = await droppedEntriesToPseudoNodes(entries, nodeId, onProgress);

          if (error) {
            showToast({
              position: "bottom-left",
              title: "Upload failed",
              description: error,
              status: "error",
              isClosable: true,
              duration: null,
            });

            onError(error);

            return;
          }

          const stack = [...rootNodeIds];
          let filesProcessed = 0,
            sizeProcessed = 0;

          while (stack.length) {
            const id = stack.pop();
            const node = nodes[id];

            const { type, name, parent, children, entry, size } = node;

            const newNode = {
              id,
              name,
              type,
              parent,
            };

            if (type === "uploaded_file") {
              const fileRef = storage.ref(
                ["/workspaces", workspaceId, "files", id].join("/")
              );

              const file = await getFilePromise(entry);

              const uploadTask = fileRef.put(file);

              const megabytes = Math.floor(node.size / 1000000);
              const estimatedSecs = megabytes / 4;

              if (estimatedSecs < 2) {
                onProgress(
                  "indeterminate",
                  `${filesProcessed}/${fileCount} Uploading ${entry.name}...`
                );
              }

              const fc = fileCount;
              const fp = filesProcessed;
              const sp = sizeProcessed;

              uploadTask.on("state_changed", (snapshot) => {
                const {
                  bytesTransferred,
                  // totalBytes
                } = snapshot;

                onProgress(
                  (bytesTransferred + sp) / totalSize,
                  (fc > 1 ? `${fp + 1}/${fc} ` : "") +
                    `Uploading ${entry.name}...`
                );
              });

              await uploadTask;

              sizeProcessed += size;

              // console.log(snapshot, entry, file);

              newNode.contentType = file.type;
              newNode.size = node.size;
              newNode.uploadedFile = {
                provider: "firebase",
                originalName: entry.name,
              };

              filesProcessed++;
            }

            // NOTE! this keeps calling the same old instance of setNode!
            // Not nice, but works as long as we turn off parent existence checks.
            await setNode(newNode);

            if (type === "folder") {
              stack.push(...children);
            }
          }

          onSuccess(
            `Uploaded ${pluralize(fileCount, "file")} and ${pluralize(
              folderCount,
              "folder"
            )}`
          );
        }
      );
    },
    [nodeId, pushJob, showToast, storage, workspaceId, setNode]
  );

  return (
    <FileDropTarget
      onDrop={onDropFiles}
      uploadLabel={"Upload to " + getNodeName(node)}
    >
      {children}
    </FileDropTarget>
  );
}

function readEntriesPromise(directoryReader) {
  return new Promise((resolve, reject) => {
    directoryReader.readEntries(resolve, reject);
  });
}

function getFilePromise(entry) {
  return new Promise((resolve, reject) => {
    entry.file(resolve, reject);
  });
}

function getDroppedFileEntries(items) {
  return Array.from(items)
    .map((item) => item.webkitGetAsEntry()) // This works in webkit and firefox
    .filter((v) => v);
}

// async function emulateUpload(node, entry, fileIndex, totalFiles, onProgress) {
//   const megabytes = Math.floor(node.size / 1000000);
//   const secs = megabytes / 4;
//   const fps = 5;

//   if (secs > 2) {
//     for (let j = 0; j < secs * fps; j++) {
//       onProgress(
//         j / (secs * fps),
//         (totalFiles > 1 ? `${fileIndex + 1}/${totalFiles} ` : "") +
//           `Uploading ${entry.name}...`
//       );

//       await new Promise((resolve) => setTimeout(() => resolve(), 100));
//     }
//   } else {
//     onProgress(
//       "indeterminate",
//       `${fileIndex}/${totalFiles} Uploading ${entry.name}...`
//     );

//     await new Promise((resolve) => setTimeout(() => resolve(), 100));
//   }
// }

async function droppedEntriesToPseudoNodes(
  entries,
  targetFolderId,
  onProgress
) {
  const nodes = {};
  const rootNodeIds = [];

  function updateProgress(fileCount, folderCount) {
    onProgress(
      "indeterminate",
      `Processing ${pluralize(fileCount, "dropped file")}, ${pluralize(
        folderCount,
        "folder"
      )}...`
    );
  }

  const throttledUpdateProgress = throttle(updateProgress, 40);

  const stack = entries.map((entry) => ({
    entry,
    parentNodeId: targetFolderId,
  }));

  let fileCount = 0,
    folderCount = 0,
    totalSize = 0;

  while (stack.length) {
    const {
      entry,
      entry: { isFile, isDirectory },
      parentNodeId,
    } = stack.shift();

    if (!isFile && !isDirectory) return;

    const id = randomId();

    let node = (nodes[id] = {
      id,
      name: entry.name,
      type: isFile ? "uploaded_file" : "folder",
      parent: parentNodeId,
      entry,
    });

    throttledUpdateProgress(fileCount, folderCount);

    if (parentNodeId === targetFolderId) {
      rootNodeIds.push(id); // root level of upload
    } else {
      nodes[parentNodeId].children.push(id);
    }

    if (fileCount + folderCount > 30000) {
      return {
        error: "We don't support uploading this many files, sorry.",
      };
    }

    if (isFile) {
      fileCount++;
      const metadata = await new Promise((resolve) =>
        entry.getMetadata(resolve)
      );
      node.size = metadata.size;
      totalSize += metadata.size;
    }

    if (isDirectory) {
      folderCount++;
      node.children = [];

      const reader = entry.createReader();
      let readEntries;

      do {
        readEntries = await readEntriesPromise(reader);
        stack.push(
          ...readEntries.map((entry) => ({ entry, parentNodeId: id }))
        );
      } while (readEntries.length > 0);
    }
  }

  return {
    nodes,
    rootNodeIds,
    fileCount,
    folderCount,
    totalSize,
  };
}
