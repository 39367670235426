import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Tag,
  Text,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdPause, MdPlayArrow } from "react-icons/md";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { DeviceContext } from "../../providers/DeviceProvider";
import {
  DragAndDropStateContext,
  useDraggable,
  useDropTarget,
} from "../../providers/DragAndDropStateProvider";
import { Window } from "../../ui/Window";
import { setMetronomeBpm } from "../../utils/metronome";
import { Center } from "../../utils/utils";

const newEmptyStep = {
  name: "",
  type: "metronome",
  seconds: 60,
  bpm: 100,
};

function formatTime(seconds) {
  seconds = Math.round(seconds);
  let secsStr = (seconds % 60) + "";
  if (secsStr.length === 1) secsStr = "0" + secsStr;

  return [Math.floor(seconds / 60), secsStr].join(":");
}

const reorder = (list, fromIndex, toIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);

  return result;
};

export function TimedRoutineEditor(props) {
  const { node } = props;

  const { speak } = useContext(DeviceContext);

  const db = useFirestore();
  const file = useFirestoreDoc(db.collection("files").doc(node.id));

  // const { width } = useWindowSize();

  const { steps = [] } = file.data() || {};

  useEffect(() => {
    if (!file.exists) {
      file.ref.set({ type: node.type });
    }
  }, [file, node.type]);

  const [isPlaying, setIsPlaying] = useState(false);

  const [playerState, setPlayerState] = useState({
    index: 0,
    secondsElapsed: 0,
  });

  const { dragFrom, dragTo } = useContext(DragAndDropStateContext);

  // const [dragToIndex, setDragToIndex] = useState(null);

  const playingStep = steps[playerState.index] || {};

  const totalSeconds = steps
    .map((step) => step.seconds)
    .reduce((a, b) => a + b, 0);

  const spentSecondsOfTotal =
    steps
      .slice(0, playerState.index)
      .map((step) => step.seconds)
      .reduce((a, b) => a + b, 0) + playerState.secondsElapsed;

  // const isAboutToEnd =
  //   playerState.secondsElapsed + (60 * 1.5) / playingStep.bpm >
  //   playingStep.seconds;

  useEffect(() => {
    setMetronomeBpm(isPlaying ? playingStep.bpm : 0);
  }, [isPlaying, playingStep.bpm, playerState.index]);

  useEffect(() => {
    return () => setMetronomeBpm(0);
  }, []);

  useEffect(() => {
    if (!isPlaying) return;
    speak(playingStep.name);
  }, [isPlaying, speak, playingStep.name]);

  useEffect(() => {
    if (!isPlaying) return;

    function update() {
      setPlayerState((state) => {
        const seconds = state.secondsElapsed + 0.2;
        const nextIndex = (state.index + 1) % steps.length;

        const isAboveIndex = seconds > playingStep.seconds;

        if (isAboveIndex) {
          return {
            index: nextIndex,
            secondsElapsed: 0.001,
          };
        } else {
          return { index: state.index, secondsElapsed: seconds };
        }
      });
    }

    const handle = setInterval(update, 200);

    return () => clearTimeout(handle);
  }, [isPlaying, playingStep.seconds, steps]);

  const reorderedSteps = useMemo(
    () =>
      dragFrom && dragTo ? reorder(steps, dragFrom.index, dragTo.index) : steps,
    [dragFrom, dragTo, steps]
  );

  const onDragAndDrop = useCallback(
    (from, to) => {
      file.ref.update({ steps: reorderedSteps });

      if (playerState.index === from.index) {
        setPlayerState((old) => ({
          ...old,
          index: to.index,
        }));
      }
    },
    [file.ref, playerState.index, reorderedSteps]
  );

  const PlayIconComponent = isPlaying ? MdPause : MdPlayArrow;

  const subNavbar = !!steps.length && (
    <Flex alignItems="center" m="0.75rem" mb="0">
      <IconButton
        colorScheme="blue"
        variant="solid"
        borderRadius="full"
        icon={<PlayIconComponent size={26} />}
        mr="0.75rem"
        onClick={() => {
          setIsPlaying((v) => !v);
        }}
      />

      <CircularProgress
        size="1.5rem"
        value={(playerState.secondsElapsed * 100) / playingStep.seconds}
        mr="0.25rem"
      />
      <Box fontSize="1.5rem" mr="0.75rem">
        {formatTime(playingStep.seconds - playerState.secondsElapsed)}
      </Box>

      <CircularProgress
        size="1.5rem"
        value={(spentSecondsOfTotal * 100) / totalSeconds}
        mr="0.25rem"
      />
      <Box fontSize="1.5rem" opacity="0.6" mr="0.75rem">
        {formatTime(totalSeconds - spentSecondsOfTotal)}
      </Box>
    </Flex>
  );

  return (
    <>
      {/* <FilesNavbarItems subMenu={width < 700 && subNavbar}>
        {width >= 700 && subNavbar}
      </FilesNavbarItems> */}
      {subNavbar}
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        overflow="auto"
      >
        <Window title={node?.name}>
          {steps.length ? (
            <Flex flexDirection="column" overflow="auto">
              {reorderedSteps.map((step, i) => {
                return (
                  <TimedRoutineRow
                    key={i}
                    {...{
                      i,
                      step,
                      steps,
                      reorderedSteps,
                      onDragAndDrop,
                      playerState,
                      setIsPlaying,
                      setPlayerState,
                      file,
                      nodeId: node.id,
                    }}
                  />
                );
              })}
            </Flex>
          ) : (
            <Center minHeight="6rem">
              <Text mt="2rem">This routine does not have any steps.</Text>
            </Center>
          )}

          <ButtonGroup size="sm" borderRadius="full" variant="link" m="1.25rem">
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              icon={<AddIcon />}
              onClick={() => {
                file.ref.update({
                  steps: FieldValue.arrayUnion(newEmptyStep),
                });
              }}
            >
              Add step
            </Button>
          </ButtonGroup>
        </Window>
      </Flex>
    </>
  );
}

const FieldValue = firebase.firestore.FieldValue;

function TimedRoutineRow(props) {
  const {
    i,
    step,
    steps,
    playerState,
    onDragAndDrop,
    setIsPlaying,
    setPlayerState,
    file,
    nodeId,
  } = props;

  const { dragFrom, dragTo } = useContext(DragAndDropStateContext);

  const ref = useRef();

  const dragItem = useMemo(
    () => ({
      type: "timed_routine",
      fileId: nodeId,
      index: i,
    }),
    [i, nodeId]
  );

  const isDragTarget = dragTo && dragItem === dragTo;
  // const isDragSource = dragFrom && dragItem === dragFrom;
  const someTimedRoutineIsDragged =
    dragFrom && dragFrom.type === "timed_routine";

  const validateDrag = useCallback(
    // Only allow drags from same editor
    (from) => from.type === "timed_routine" && from.fileId === nodeId,
    [nodeId]
  );

  const draggableProps = useDraggable({ dragItem });

  const dragTargetProps = useDropTarget({
    ref,
    dragItem,
    onDragAndDrop,
    validateDrag,
  });

  const { name, type, seconds, bpm } = step;

  const updateStep = (values) => {
    file.ref.update({
      steps: steps.map((iterated) =>
        iterated === step ? { ...step, ...values } : iterated
      ),
    });
  };

  // let backgroundColor = null;

  // if (isDragTarget) backgroundColor = "rgba(127, 127, 127, 0.1)";

  return (
    <Flex
      key={i}
      ref={ref}
      // width="15rem"
      // flexDirection="column"
      alignItems="baseline"
      borderTop="1px solid rgba(127, 127, 127, 0.1)"
      // boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      // margin="0.5rem 1.25rem"
      borderRadius="0.25rem"
      opacity={isDragTarget ? 0 : 1}
      style={{
        // backgroundColor, // TODO
        transition: "background-color 200ms opacity 250ms",
      }}
      {...dragTargetProps}
      {...draggableProps}
    >
      <Button
        variant="ghost"
        colorScheme="blue"
        isActive={i === playerState.index && !someTimedRoutineIsDragged}
        leftIcon={<MdPlayArrow />}
        textAlign="left"
        justifyContent="flex-start"
        title="Play"
        mr="1.25rem"
        onClick={() => {
          setIsPlaying(true);
          setPlayerState({
            index: i,
            secondsElapsed: 0,
          });
        }}
      >
        <Box width="3rem">
          <Tag
            rounded="full"
            // variant="outline"
            size="sm"
            mx="0.75rem"
          >
            {i + 1}
          </Tag>
        </Box>
      </Button>

      <Editable
        key={"name-" + name}
        flexGrow="1"
        placeholder="Click to enter name..."
        defaultValue={name}
        onSubmit={(name) => updateStep({ name })}
      >
        <EditableInput title="Edit name..." />
        <EditablePreview
          title={name ? "Edit name..." : null}
          cursor="pointer"
        />
      </Editable>

      <Box width="7rem">{type}</Box>

      <Editable
        key={"bpm_" + bpm}
        width="5rem"
        placeholder="Enter bpm..."
        defaultValue={bpm + " bpm"}
        onSubmit={(bpm) => {
          bpm = Math.min(Math.max(10, parseInt(bpm)), 300);
          updateStep({ bpm });
        }}
      >
        <EditableInput title="Edit bpm..." />
        <EditablePreview title={"Edit bpm..."} cursor="pointer" />
      </Editable>

      <Editable
        key={"seconds_" + seconds}
        width="3rem"
        placeholder="Enter length..."
        defaultValue={formatTime(seconds)}
        onSubmit={(str) => {
          const [m, s] = str.split(":");

          let seconds = s ? parseInt(m) * 60 + parseInt(s) : parseInt(m);

          seconds = Math.min(Math.max(seconds, 1), 60 * 60);

          updateStep({ seconds });
        }}
      >
        <EditableInput title="Edit length..." />
        <EditablePreview title={"Edit length..."} cursor="pointer" />
      </Editable>

      <IconButton
        variant="ghost"
        // size="lg"
        icon={<DeleteIcon />}
        onClick={() => {
          file.ref.update({
            steps: steps.filter((item, comparedI) => comparedI !== i),
          });
        }}
      />
    </Flex>
  );
}
