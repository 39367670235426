import React, { useState, useCallback } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import { useRef } from "react";
import { FadePresence } from "../utils/utils";
import { MdFileUpload } from "react-icons/md";

export default function FileDropTarget(props) {
  const {
    accept = ["Files"],
    onDrop = () => {},
    uploadLabel,
    children,
    ...rest
  } = props;

  const [dragOverDataTransfer, setDragOverDataTransfer] = useState(null);
  const targetRef = useRef();

  const validate = useCallback(
    (event) =>
      accept.find((acceptedType) =>
        event.dataTransfer.types.includes(acceptedType)
      ),
    [accept]
  );

  return (
    <Flex
      className="FileDropTarget"
      ref={targetRef}
      flexDirection="column"
      flexGrow="1"
      alignSelf="stretch"
      onDragEnter={(event) => {
        if (!validate(event)) return;

        event.stopPropagation();
        event.preventDefault();

        setDragOverDataTransfer(event.dataTransfer);
      }}
      onDragOver={(event) => {
        if (!validate(event)) return;
        event.stopPropagation();
        event.preventDefault();
      }}
      onDragLeave={(event) => {
        if (!validate(event)) return;
        event.stopPropagation();
        event.preventDefault();

        if (
          !targetRef.current ||
          targetRef.current.contains(event.relatedTarget)
        )
          return;

        setDragOverDataTransfer(null);
      }}
      onDrop={(event) => {
        if (!validate(event)) return;

        event.stopPropagation();
        event.preventDefault();
        setDragOverDataTransfer(null);
        onDrop(event.dataTransfer);
      }}
    >
      <FadePresence
        display={dragOverDataTransfer ? "flex" : "none"}
        flexGrow="1"
        alignSelf="stretch"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border={dragOverDataTransfer ? "0.5rem dashed lightblue" : undefined}
        borderColor="blue.500"
        {...rest}
      >
        <Box as={MdFileUpload} fontSize="5rem" color="blue.500" />

        <Text fontSize="1.3rem" opacity="0.9" fontWeight="600">
          {uploadLabel}
        </Text>
      </FadePresence>

      <Flex
        className="FileDropTarget-NotActiveWrapper"
        flexGrow="1"
        display={dragOverDataTransfer ? "none" : undefined}
      >
        {children}
      </Flex>
    </Flex>
  );
}
