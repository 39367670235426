import { useCallback, useRef } from "react";

// function preventDefault(event) {
//   console.log("Intercept");
//   event.preventDefault();
// }

export const useLongTouch = (callback, { delay = 300 } = {}) => {
  const timeout = useRef();
  // const target = useRef();

  const start = useCallback(
    (event) => {
      // prevent ghost click on mobile devices
      // console.log("start", event);

      timeout.current = setTimeout(() => {
        // console.log("timeout");
        callback(event);
        // timeout.current = undefined;

        // target.current = event.target;
        // target.current.addEventListener("mouseup", preventDefault);
      }, delay);
    },
    [callback, delay]
  );

  const clear = useCallback((event) => {
    if (timeout.current !== undefined) {
      // event.preventDefault();
      // console.log("clear", event);

      clearTimeout(timeout.current);
      // target.current?.removeEventListener("mouseup", preventDefault);
      // target.current = undefined;
      timeout.current = undefined;
    }
  }, []);

  const result = {
    // onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    // onMouseUp: clear,
    // onMouseLeave: clear,
    onTouchMove: clear,
    onTouchEnd: clear,
  };

  if (timeout.current !== undefined) {
    // result.onClick = (event) => event.preventDefault();
    result.onContextMenu = (event) => event.preventDefault();
  }

  return result;
};
