import { Box, Button, Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { usePaperColor } from "../utils/appColors";
import { FadePresence } from "../utils/utils";

export function SettingsCard(props) {
  const { header, children, ...rest } = props;
  const bgColor = usePaperColor();

  return (
    <FadePresence
      display="flex"
      flexDirection="column"
      padding="1.25rem"
      mb="1.25rem"
      borderRadius="0.25rem"
      boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      // maxWidth="50rem"
      backgroundColor={bgColor}
      // backgroundColor="rgba(255, 255, 255, 0.9)"
      style={{ backdropFilter: "blur(40px)" }} // NOT WORKING?
      // position="relative"
      // zIndex="1"
      id={header && header.replace(/ /g, "_")}
      {...rest}
    >
      <Box>
        {header && <SettingsHeader>{header}</SettingsHeader>}
        {children}
      </Box>
    </FadePresence>
  );
}

export function ItemCard(props) {
  const { children, ...rest } = props;
  const bgColor = usePaperColor();

  return (
    <Flex
      direction="column"
      padding="0.75rem"
      m="0 1.25rem 1.25rem 0"
      borderRadius="0.25rem"
      border="1px solid rgba(150, 150, 150, 0.2)"
      backgroundColor={bgColor}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export function SettingsHeader(props) {
  const { children, ...rest } = props;

  return (
    <>
      <Heading as="h3" fontSize="1.2rem" fontWeight="600" {...rest}>
        {children}
      </Heading>
      <Divider mb="1.25rem" />
    </>
  );
}

export function SettingsSubHeader(props) {
  const { children } = props;

  return (
    <Box mb="3rem">
      <Box>{children}</Box>
    </Box>
  );
}

const scrollToItem = (event, item) => {
  const substituted = item.replace(/ /g, "_");
  event.preventDefault();
  // eslint-disable-next-line
  document.getElementById(substituted)?.scrollIntoView({ behavior: "smooth" });
};

export function SettingsIndex(props) {
  const { items, ...rest } = props;

  return (
    <Box ml="0.5rem" pb="1.25rem" minWidth="15rem" {...rest}>
      {items.map((item) => {
        return Array.isArray(item) ? (
          item.map((subItem) => {
            return (
              <Button
                key={subItem}
                as="a"
                href={"#" + subItem.replace(/ /g, "_")}
                onClick={(event) => scrollToItem(event, subItem)}
                display="flex"
                justifyContent="flex-start"
                variant="ghost"
              >
                {subItem}
              </Button>
            );
          })
        ) : (
          <Heading
            key={item}
            as="h3"
            fontSize="1.3rem"
            fontWeight="600"
            mt="1.25rem"
            mb="0.75rem"
            onClick={(event) => scrollToItem(event, item)}
          >
            {item}
          </Heading>
        );
      })}
    </Box>
  );
}
