/*
  Originally, all of this logic was inside a context provider. Each action was a useCallback function. This became problematic because it bound the fileTree to each function at the time of reference. For example, when uploading files, the parent checks in setNode would fail for files inside folders created as part of that upload task, because it was still using a setNode that was bound to an old fileTree, all the way down the promise chain.

  The actions must always use the latest data, yet remain the same function, within each workspace.
*/

import hash from "hash-sum";
import { useEffect, useMemo, useState } from "react";
import { db, FieldValue, workspaceDatasRef } from "../firebase";
import { folderResourceTypes } from "../utils/resourceUtils";
import { findPath, randomId } from "../utils/utils";

function bindMethods(self) {
  Object.getOwnPropertyNames(Object.getPrototypeOf(self)).forEach((key) => {
    if (self[key] instanceof Function && key !== "constructor")
      self[key] = self[key].bind(self);
  });
}

export class WorkspaceDataController {
  constructor(workspaceId) {
    if (!workspaceId) throw new Error("Missing workspaceId");

    bindMethods(this);

    this.workspaceId = workspaceId;
    this.ref = workspaceDatasRef.doc(workspaceId);

    this.ready = false;
    this.data = null;

    function notifyCallback(self) {
      self._subscriptionCallback?.({
        data: self.data,
        ready: self.ready,
        ref: self.ref,
        controller: self,
        error: self.error,
      });
    }

    this._cancelFirestoreSub = this.ref.onSnapshot(
      (doc) => {
        this.ready = true;
        this.data = doc.data();
        if (process.env.NODE_ENV === "development") {
          this.data && this.validateData(this.data);
        }
        notifyCallback(this);
      },
      (error) => {
        this.ready = true;
        this.data = null;
        this.error = error;

        notifyCallback(this);
      }
    );
  }

  validateData(data) {
    const { fileTree } = data;

    const strangeNodes = [];
    Object.keys(fileTree).forEach((id) => {
      const node = fileTree[id];

      if (typeof node.name !== "string" || !node.id) {
        strangeNodes.push(id);
      } else if (node.name !== "$ROOT" && !fileTree[node.parent]) {
        strangeNodes.push(id);
      }
    });

    if (strangeNodes.length) {
      strangeNodes.forEach((id) => {
        const node = fileTree[id];
        console.log("Strange node:", id, node, () => this.trashNode(id));
      });
      console.log("Trash all:", () => strangeNodes.forEach(this.trashNode));
    }
  }

  subscribe(fn) {
    this._subscriptionCallback = fn;
    return () => {
      this._cancelFirestoreSub();
      this._subscriptionCallback = null;
    };
  }

  async setNode(node) {
    const { fileTree } = this.data;

    if (!node) throw new Error("Missing node");
    if (!node.type) throw new Error("Missing node type");
    if (node.id === "$ROOT") throw new Error("Tried to set root node");

    if (!fileTree[node.parent]) {
      console.log("Invalid parent id:", node);
      throw new Error("Invalid node parent id:", node.parent);
    }

    const existingVersion = fileTree[node.id];

    if (existingVersion && existingVersion.parent !== node.parent) {
      throw new Error("Cannot change parent while editing");
    }

    // Default values:
    if (!node.id) node.id = randomId(); // TODO?

    if (!node.timestamps) node.timestamps = {};
    if (!node.timestamps.opened)
      node.timestamps.opened = FieldValue.serverTimestamp();

    if (!node.counts) node.counts = {};
    if (node.counts.opened === undefined) node.counts.opened = 0;

    const patch = {};

    if (!fileTree) {
      patch["fileTree.$ROOT"] = {
        id: "$ROOT",
        name: "$ROOT",
        type: "folder",
        children: [],
      };
    }

    patch["fileTree." + node.id] = node;

    if (!existingVersion) {
      patch["fileTree." + node.parent + ".children"] = FieldValue.arrayUnion(
        node.id
      );
    }

    await this.ref.update(patch);
  }

  async moveNode(nodeId, newParentId) {
    const { fileTree } = this.data;

    const node = fileTree[nodeId];
    if (!node) throw new Error("Invalid nodeId " + nodeId);
    if (!fileTree[newParentId])
      throw new Error("Invalid newParentId: " + newParentId);
    if (!folderResourceTypes.includes(fileTree[newParentId].type))
      throw new Error(
        "newParentId is not a folder type, but a " + fileTree[newParentId].type
      );

    const path = findPath(fileTree, newParentId);
    if (path.find((ancestor) => ancestor.id === nodeId)) {
      throw new Error("Tried to move a folder to a subfolder of itself");
    }

    const patch = {
      ["fileTree." + nodeId + ".parent"]: newParentId,
      ["fileTree." + node.parent + ".children"]: FieldValue.arrayRemove(nodeId),
      ["fileTree." + newParentId + ".children"]: FieldValue.arrayUnion(nodeId),
    };

    await this.ref.update(patch);
  }

  async trashNode(nodeId) {
    const { fileTree } = this.data;
    // TODO: large delete operations may have to be split into several operations.
    // TODO: locking in case of player, pending upload etc

    const rootNodeToTrash = fileTree[nodeId];
    if (!nodeId) throw new Error("Missing node id");
    if (nodeId === "$ROOT") throw new Error("Tried to delete root node");
    if (!rootNodeToTrash) throw new Error("Node does not exist");

    const traversalOrder = getChildTraversalOrderTopFirst(fileTree, nodeId);
    const trashId = randomId();
    const trashNodes = {};

    const patch = {};

    if (rootNodeToTrash.parent) {
      patch[
        "fileTree." + rootNodeToTrash.parent + ".children"
      ] = FieldValue.arrayRemove(nodeId);
    } else {
      console.log(
        "trashNode: Unexpected missing parent field (proceeding with thrash)"
      );
    }

    const batch = db.batch();

    // Traverse nodes in reverse
    for (let i = traversalOrder.length - 1; i >= 0; i--) {
      const nodeId = traversalOrder[i];
      const node = fileTree[nodeId];

      trashNodes[nodeId] = node;
      patch["fileTree." + nodeId] = FieldValue.delete();
    }

    batch.update(this.ref, patch);

    batch.set(this.ref.collection("trashes").doc(trashId), {
      id: trashId,
      timestamp: FieldValue.serverTimestamp(),
      traversalOrder,
      trashNodes,
    });

    await batch.commit();
  }

  timestampNode(nodeId, event = "opened") {
    const { fileTree } = this.data;

    if (!fileTree[nodeId]) return; // Ignore because it might have been deleted etc..

    this.ref.update({
      [["fileTree", nodeId, "timestamps." + event].join(
        "."
      )]: FieldValue.serverTimestamp(),
      [["fileTree", nodeId, "counts." + event].join(".")]: FieldValue.increment(
        1
      ),
    });
  }
}

export function useController(ControllerClass, ...args) {
  const [data, setData] = useState({ ready: false, data: null });

  const argsHash = hash(args);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controller = useMemo(() => new ControllerClass(...args), [argsHash]);

  useEffect(() => controller.subscribe(setData), [controller]);

  return data;
}

export function getChildTraversalOrderTopFirst(nodes, id, result = [id]) {
  const node = nodes[id];
  if (!node) throw new Error("Invalid node id: " + id);

  (node.children || []).forEach((childId) => {
    result.push(childId);
    getChildTraversalOrderTopFirst(nodes, childId, result);
  });

  return result;
}
