import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";

export const Crumb = React.forwardRef((props, ref) => {
  const { interactive, isLast, isTargeted, children, ...rest } = props;

  const name = children || props.title || <em>Untitled</em>;

  if (!interactive)
    return (
      <Text fontWeight="400" ml={interactive ? "0.5rem" : undefined}>
        {name}
      </Text>
    );

  return (
    <Button
      ref={ref}
      overflow="hidden"
      textOverflow="ellipsis"
      flexShrink={isLast ? "1" : "1000"}
      variant={isTargeted || isLast ? "solid" : "link"}
      colorScheme={isTargeted ? "blue" : "gray"}
      // height="auto"
      // p="0"
      pl={"0.75rem"}
      pr={"0.5rem"}
      {...rest}
    >
      <Box
        as="span"
        overflow="hidden"
        // p={isTargeted || isLast ? "0.5rem" : undefined}
        // pr={rightIcon ? "0.25rem" : undefined}
        textOverflow="ellipsis"
      >
        {name}
      </Box>
    </Button>
  );
});

export function CrumbSeparator(props) {
  return (
    <Box as="span" color="#aaa" mx="0.25rem" {...props}>
      /
    </Box>
  );
}
