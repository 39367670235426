import { Badge, Box, Flex, Textarea } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useThrottle } from "react-use";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { usePaperColor } from "../../utils/appColors";
import { pluralize } from "../../utils/utils";

// const emptySlateDoc = [
//   {
//     type: "paragraph",
//     children: [
//       {
//         text: "",
//       },
//     ],
//   },
// ];

// const plugins = [SoftBreak({shift: true})];

export function NoteEditor(props) {
  const {
    node: { id },
  } = props;

  const db = useFirestore();
  const fileRef = useMemo(() => db.collection("files").doc(id), [db, id]);
  const file = useFirestoreDoc(fileRef);

  const { text = "" } = file.data() || {};

  const [value, setValue] = useState(text);
  const [lastSaved, setLastSaved] = useState(new Date());

  const throttledValue = useThrottle(value, 1000 * 5);

  useEffect(() => {
    setLastSaved(new Date());
    console.log("save");
    fileRef.set({ text: throttledValue }, { merge: true });
  }, [throttledValue, fileRef]);

  const isSaved = text === value;

  const secsSinceSave = Math.floor((new Date() - lastSaved) / 1000);

  const lineCount = (value.match(/\n/g) || "").length + 1;
  const wordCount = (value.match(/\s+/g) || "").length + 1;

  const bg = usePaperColor();

  // https://github.com/ianstormtaylor/slate/issues/3334
  // const handleChange = useCallback(
  //   (val) => {
  //     ReactDOM.flushSync(() => {
  //       setValue(val);
  //       file.ref.set({ val });
  //     });
  //   },
  //   [file.ref]
  // );

  return (
    <>
      {/* <FilesNavbarItems>
        <Box flexGrow="1" />
      </FilesNavbarItems> */}

      <Flex
        flexDirection="column"
        m={["0", "0.75rem"]}
        flexGrow="1"
        maxW="80ch"
        backgroundColor={bg}
        borderRadius="0.5rem"
      >
        {/* <RichTextEditor value={value} onChange={handleChange} /> */}

        <Textarea
          backgroundColor={bg}
          // mx="5vw"
          // mx="auto"
          flexGrow="1"
          flexShrink="1"
          placeholder="Write here..."
          padding="1.25rem"
          // h="100vh"
          resize="none"
          border="none"
          // fontSize="clamp(1em, calc(0.8em + 0.4vh), 1.1em)"
          // shadow="0 0 30px rgba(0, 0, 0, 0.15)"
          // _focus={{
          //   shadow: "0 0 30px rgba(0, 0, 120, 0.15)",
          // }}
          value={value}
          onBlur={(event) => {
            setLastSaved(new Date());
            file.ref.set({ text: event.target.value }, { merge: true });
          }}
          onChange={(event) => setValue(event.target.value)}
        />
        <Flex borderTop="1px solid rgba(127,127,127,0.2)" p="0.5rem">
          {!!value && (
            <Box opacity="0.3" flexGrow={1}>
              {pluralize(lineCount, "line")}, {pluralize(wordCount, "word")}
            </Box>
          )}

          {!!(isSaved || secsSinceSave) && (
            <Badge alignSelf="center" colorScheme={isSaved ? "green" : "gray"}>
              Saved {!isSaved && pluralize(secsSinceSave, "second") + " ago"}
            </Badge>
          )}
        </Flex>
      </Flex>
    </>
  );
}
