import { useEffect, useState } from "react";
import {
  applyToDataHistoryImage,
  applyToDataImage,
  blankDataHistoryImage,
  blankDataImage,
  DataId,
  Delta,
  DeltaBatcher,
} from "./deltas";
import { EventStreamer } from "./eventStreams";
import { Node, NodeType } from "./nodeTypes";
import { createIdSetReducer, EXCLUDED, mapping, partition } from "./queries";

/* 

  J-base plans:

  async read/write
  timestamps (created, modified)
  storage adapter
  transactions
  changing schema and indices at runtime
  migrations
  send queue and retrying
  queries: subscribe, image
  retry with new date.
  Different finalities of events.
  Proposed, finalized.

  joined/mapped queries (really joining indexes and nodes), for example:
    relationshipQuery.mapTo((relData) => relData.childId)

*/

// The event stream and batcher

const streamer = new EventStreamer<Delta<Node>>();
const batch = new DeltaBatcher<Node>(streamer);

// Node and history images

const nodes = blankDataImage<Node>();
const nodeHistories = blankDataHistoryImage<Node>();

// Indexes

// function countReducer<D>(
//   value: number,
//   delta: Delta<D>,
//   newNode?: D,
//   oldNode?: D
// ) {
//   if (newNode && !oldNode) return value + 1;
//   if (oldNode && !newNode) return value - 1;
//   return value;
// }

// const totalNodesCount: Query<Node, number> = {
//   reducer: countReducer,
// };

// const nodeTypeCounts: Query<Node, number, DataId> = {
//   reducer: countReducer,
//   partition: (node: Node) => node.type,
// };

function onlyRelationshipNodes(node: Node) {
  return node.type === NodeType.Relationship ? node : EXCLUDED;
}

function onlyFileNodes(node: Node) {
  return node.type === NodeType.File ? node : EXCLUDED;
}

const relationshipsByParent = mapping(
  onlyRelationshipNodes,
  partition((node) => node.parent, createIdSetReducer())
);

const relationshipsByChild = mapping(
  onlyRelationshipNodes,
  partition((node) => node.child, createIdSetReducer())
);

const fileNodeCount = mapping(onlyFileNodes, createIdSetReducer());

/*

  initializeReducer :: index -> [state, generic updater, subscribe: ((...partitionParams) => {}) => Function]

  subscription = index + any partition keys

*/

const rbps = relationshipsByParent.init();
const rbcs = relationshipsByChild.init();
const fncs = fileNodeCount.init();

function applyToImage(delta: Delta<Node>) {
  const oldNode = nodes.get(delta.dataId);

  applyToDataImage(delta, nodes);
  applyToDataHistoryImage(delta, nodeHistories);

  const newNode = nodes.get(delta.dataId); // TODO clean this up so we don't have to do lookup here

  relationshipsByParent.apply(rbps, delta.dataId, newNode, oldNode);
  relationshipsByChild.apply(rbcs, delta.dataId, newNode, oldNode);
  fileNodeCount.apply(fncs, delta.dataId, newNode, oldNode);
}

streamer.listen({
  filter: (delta: Delta<Node>) => true,
  process: applyToImage,
});

type ResourceLoadingState = "fresh" | "cached" | "loading";

export function useNode(nodeId: DataId) {
  const [value, setValue] = useState<Node | undefined>(nodes.get(nodeId));

  useEffect(() => {
    // subscribe this on the server as well..
    // move from filter to serializeable query..

    const cancel = streamer.listen({
      filter: (delta: Delta<Node>) => true,
      process: (delta: Delta<Node>) => {
        if (delta.dataId === nodeId) {
          setValue(nodes.get(nodeId));
        }
      },
    });
    return () => cancel();
  });

  const loadingState: ResourceLoadingState = "fresh"; // TODO

  return [value, loadingState];
}

// Next: persistence

batch.set(0, { name: "jostein", type: NodeType.File, tags: [] });
batch.set(1, { name: "kari", type: NodeType.File, tags: [] });
batch.set(2, { name: "hallvard", type: NodeType.File, tags: [] });
batch.set(3, { name: "even", type: NodeType.File, tags: [] });
batch.set(4, { name: "unni", type: NodeType.File, tags: [] });
batch.set(6, { name: "finn", type: NodeType.File, tags: [] });

// batch.update(0, { name: "Hello there" });
// batch.update(1, { name: "Hello there", tags: ["Hello"] });
// batch.delete(2);

batch.set(100, { type: NodeType.Relationship, tags: [], parent: 1, child: 0 });
batch.set(101, { type: NodeType.Relationship, tags: [], parent: 2, child: 0 });
batch.set(102, { type: NodeType.Relationship, tags: [], parent: 2, child: 3 });
batch.set(103, { type: NodeType.Relationship, tags: [], parent: 4, child: 3 });
batch.set(104, { type: NodeType.Relationship, tags: [], parent: 6, child: 1 });
batch.commit();

function idSetToNodes(set: Set<DataId>, getParent: boolean): Node[] {
  const result: Node[] = [];
  set.forEach((id) => {
    const relationshipNode = nodes.get(id);
    if (relationshipNode?.type !== NodeType.Relationship) return;
    const node = getParent
      ? nodes.get(relationshipNode.parent)
      : nodes.get(relationshipNode.child);
    if (node) result.push(node);
  });

  return result;
}

if (process.env.NODE_ENV === "development") {
  rbps.forEach((value, key) => {
    console.log(
      "Children of ",
      key !== undefined && nodes.get(key),
      ": ",
      idSetToNodes(value, false)
    );
  });

  rbcs.forEach((value, key) => {
    console.log(
      "Parents of ",
      key !== undefined && nodes.get(key),
      ": ",
      idSetToNodes(value, true)
    );
  });
}
