import { Box, Button, ButtonGroup, Flex, Text } from "@chakra-ui/react";
import firebase from "firebase/app";
import React, { useCallback, useContext } from "react";
import { Ghost } from "react-kawaii";
import { useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage,
} from "reactfire";
import { ActiveWorkspaceContext } from "../providers/ActiveWorkspaceProvider";
import { Window } from "../ui/Window";
import { IconLookup } from "../utils/resourceUtils";
import {
  Center,
  getNodeIcon,
  pluralize,
  TimeAgo,
  useUiBlockingCallback,
} from "../utils/utils";
import { PathCrumbs } from "./PathCrumbs";

function RecycleBinPageWrapper(props) {
  const { children } = props;

  return (
    <>
      <Window p="1.25rem">{children}</Window>
    </>
  );
}

export default function RecycleBinPage() {
  const { workspaceId } = useParams();
  const { fileTree, activeWorkspaceDataRef } = useContext(
    ActiveWorkspaceContext
  );

  const db = useFirestore();
  const storage = useStorage();
  const trashesRef = activeWorkspaceDataRef.collection("trashes");
  const trashes = useFirestoreCollectionData(trashesRef);

  const FieldValue = firebase.firestore.FieldValue;

  const [isWorking, blockUi] = useUiBlockingCallback();

  const restoreTrash = useCallback(
    async (trash) => {
      const { id, traversalOrder, trashNodes } = trash;

      const rootNodeId = traversalOrder[0];
      const rootNode = trashNodes[rootNodeId];
      const parentId = rootNode.parent;
      const parent = fileTree[parentId];

      if (!parent)
        throw new Error("Attempted to restore trashed without existing parent");

      const patch = {
        ["fileTree." + parentId + ".children"]: FieldValue.arrayUnion(
          rootNodeId
        ),
      };

      // TODO: may have to be split in several operations if it becomes very large

      traversalOrder.forEach((nodeId) => {
        if (fileTree[nodeId])
          throw new Error(
            "Invariant: trashed node id also present in fileTree"
          );
        patch["fileTree." + nodeId] = trashNodes[nodeId];
      });

      const batch = db.batch();
      batch.update(activeWorkspaceDataRef, patch);
      batch.delete(activeWorkspaceDataRef.collection("trashes").doc(id));

      await batch.commit();
    },
    [FieldValue, activeWorkspaceDataRef, db, fileTree]
  );

  const dropTrash = useCallback(
    async (trash, droppedDependents = {}) => {
      const { id: trashId, traversalOrder, trashNodes } = trash;

      // This is used to prevent trashes from attempted deletion several times
      // when called on several nodes in succession during a single callback.
      if (droppedDependents[trash.id]) {
        return droppedDependents;
      }

      droppedDependents[trashId] = true;

      const batch = db.batch();
      const afterCallbacks = [];

      // iterate over nodes in reverse traversal order, dropping each one
      for (let i = traversalOrder.length - 1; i >= 0; i--) {
        const nodeId = traversalOrder[i];
        const node = trashNodes[nodeId];

        // check if any trashes has this node as a parent

        for (let j = 0; j < trashes.length; j++) {
          const dependentTrash = trashes[j];

          const { traversalOrder, trashNodes } = dependentTrash;
          const rootNodeId = traversalOrder[0];
          const rootNode = trashNodes[rootNodeId];
          if (rootNode.parent === nodeId) {
            // if it does, drop that trash first
            await dropTrash(dependentTrash, droppedDependents);
          }
        }

        // Handle node destroy cleanup:

        if (node.type === "uploaded_file") {
          const fileRef = storage.ref(
            ["/workspaces", workspaceId, "files", nodeId].join("/")
          );

          // can't be part of the batch because it's a file, not a firestore doc
          afterCallbacks.push(() => fileRef.delete());
        }

        batch.delete(db.collection("files").doc(nodeId));

        // TODO: To support incremental trash drop:
        // remove id from traversalOrder and remove the node
      }

      batch.delete(activeWorkspaceDataRef.collection("trashes").doc(trashId));

      await batch.commit();
      await Promise.all(afterCallbacks.map((cb) => cb()));

      return droppedDependents;
    },
    [db, activeWorkspaceDataRef, trashes, workspaceId, storage]
  );

  // this is a list of trash ids that are dropped as we go because they
  // depend on something dropped earlier. We keep track of them to avoid
  // trying to drop a trash that was already dropped.

  const dropAllTrashes = useCallback(async () => {
    const droppedDependents = {};

    for (let i = 0; i < trashes.length; i++) {
      const trash = trashes[i];

      await dropTrash(trash, droppedDependents);
    }
  }, [trashes, dropTrash]);

  if (!trashes.length) {
    return (
      <RecycleBinPageWrapper>
        <Center>
          <Ghost size={240} mood="blissful" color="#E0E4E8" />
          <Text fontSize="1.5rem" mt="1.25rem">
            The recycle bin is empty!
          </Text>
        </Center>
      </RecycleBinPageWrapper>
    );
  }

  const restorableTrashes = [];
  const unrestorableTrashes = [];

  trashes.forEach((trash) => {
    const { traversalOrder, trashNodes } = trash;

    const rootNodeId = traversalOrder[0];
    const rootNode = trashNodes[rootNodeId];
    const parentId = rootNode.parent;

    if (fileTree[parentId]) {
      restorableTrashes.push(trash);
    } else {
      unrestorableTrashes.push(trash);
    }
  });

  return (
    <RecycleBinPageWrapper>
      <Box mb="1.25rem">
        <Text>The following items have been deleted and can be restored:</Text>
        {isWorking && (
          <Text fontSize="1.2rem">
            <span className="color-globe" my="1.25rem" /> Deleting...
          </Text>
        )}
      </Box>

      <table>
        <thead>
          <tr>
            <th>File</th>
            <th>Location</th>
            <th>Total items</th>
            <th>Deleted</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {restorableTrashes.map((trash) => {
            const { id, timestamp, traversalOrder, trashNodes } = trash;

            const rootNodeId = traversalOrder[0];
            const rootNode = trashNodes[rootNodeId];
            const parentId = rootNode.parent;
            const parent = fileTree[parentId];

            // if (!parent) {
            //   return null;
            //   // only allowed to restore things with existing parents.
            //   // enforces meaningful restore order.
            // }

            return (
              <Box as="tr" key={id}>
                <td>
                  <Flex alignItems="center">
                    <IconLookup
                      icon={getNodeIcon(rootNode)}
                      fontSize="1.3rem"
                      mr="0.75rem"
                    />
                    {rootNode.name}
                  </Flex>
                </td>
                <td>
                  {parent ? (
                    <PathCrumbs targetNodeId={rootNode.parent} />
                  ) : (
                    <Text opacity="0.5">Also deleted</Text>
                  )}
                </td>
                <td>{traversalOrder.length}</td>
                <td>
                  <TimeAgo date={timestamp} />
                </td>
                <td>
                  <ButtonGroup spacing={0} size="sm" variant="ghost">
                    {parent && (
                      <Button
                        disabled={isWorking}
                        colorScheme="blue"
                        onClick={() => blockUi(restoreTrash(trash))}
                      >
                        Restore
                      </Button>
                    )}
                    <Button
                      disabled={isWorking}
                      colorScheme="red"
                      onClick={() => blockUi(dropTrash(trash))}
                    >
                      Delete permanently
                    </Button>
                  </ButtonGroup>
                </td>
              </Box>
            );
          })}
        </tbody>
      </table>

      {!!unrestorableTrashes.length && (
        <Text opacity="0.8" my="1.25rem">
          {pluralize(unrestorableTrashes.length, "bin")} not listed because they
          are inside other bins.
        </Text>
      )}

      <ButtonGroup>
        {/* <Button disabled={isWorking} colorScheme="blue" my="1.25rem">
          Restore all
        </Button> */}
        <Button
          disabled={isWorking}
          colorScheme="red"
          my="1.25rem"
          onClick={() => blockUi(dropAllTrashes())}
        >
          Permanently delete all
        </Button>
      </ButtonGroup>
    </RecycleBinPageWrapper>
  );
}
