import "focus-visible/dist/focus-visible";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./app/styles.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "./model/images";

ReactDOM.render(
  <>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>,
  document.getElementById("root")
);

// ReactDOM.unstable_createBlockingRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
