import React, { useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNodeDrag } from "../hooks/useNodeDrag";
import { ItemButton } from "../ItemButton";
import { ActiveWorkspaceContext } from "../providers/ActiveWorkspaceProvider";
import { DragAndDropStateContext } from "../providers/DragAndDropStateProvider";
import { useLongTouch } from "../utils/useLongPress";
import { capitalize, getNodeIcon, getNodeName } from "../utils/utils";

export function DraggableNodeItemButton(props) {
  const { node, ...rest } = props;

  const ref = useRef();

  const { selectedNodeIds, toggleNodeSelected } = useContext(
    ActiveWorkspaceContext
  );

  const {
    draggableProps,
    dragTargetProps,
    isTargeted,
    isDragged,
  } = useNodeDrag({ node, ref });

  const dragdrop = useContext(DragAndDropStateContext);

  const longPressProps = useLongTouch(
    () => !dragdrop.dragFrom && toggleNodeSelected(node.id),

    {}
  );

  const propsToDisableLink = selectedNodeIds
    ? {
        // as: "button",
        // to: undefined,
        onClick: (event) => {
          // console.log("click (preventDefault)");
          event.preventDefault();
          if (selectedNodeIds) toggleNodeSelected(node.id);
        },
      }
    : {};

  return (
    <NodeItemButton
      ref={ref}
      node={node}
      isDragged={isDragged}
      isDragTargeted={isTargeted}
      // cursor="grab"
      // _active={{ cursor: "grabbing" }}
      {...(selectedNodeIds ? {} : draggableProps)}
      {...dragTargetProps}
      {...(dragdrop.dragFrom ? {} : longPressProps)}
      {...propsToDisableLink}
      {...rest}
      selected={selectedNodeIds?.[node.id]}
    />
  );
}

export const NodeItemButton = React.forwardRef((props, ref) => {
  const { node, ...rest } = props;
  const { workspaceId } = useParams();

  return (
    <ItemButton
      ref={ref}
      as={Link}
      to={`/${workspaceId}/files/${node.id}`}
      name={getNodeName(node)}
      title={node.resourceType ? capitalize(node.resourceType) : undefined}
      icon={getNodeIcon(node)}
      thumbnailId={node?.contentType?.startsWith("image/") && node?.id}
      node={node}
      {...rest}
    />
  );
});
