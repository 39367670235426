import { Flex } from "@chakra-ui/react";
import React from "react";
import { useWindowSize } from "react-use";
import { usePaperColor } from "../utils/appColors";

export function Window(props) {
  const { children, ...rest } = props;
  const bg = usePaperColor();

  const { width } = useWindowSize();

  const compact = width < 750;

  return (
    <Flex
      flexGrow="1"
      flexDirection="column"
      overflow="auto"
      // alignSelf="center"
      // width="100vw"
      maxWidth="71rem"
      boxSizing="border-box"
      backgroundColor={bg}
      // p="1.25rem"
      borderRadius={"0.25rem"}
      margin={compact ? "0.75rem 0" : "0.75rem"}
      boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      {...rest}
    >
      {children}
    </Flex>
  );
}
