import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

// TODO:

export const app = firebase.initializeApp({
  apiKey: "AIzaSyCPewPf_SGnX8gZFX1zDUVB6roE1TVx5BI",
  authDomain: "jotter-8e6cd.firebaseapp.com",
  databaseURL: "https://jotter-8e6cd.firebaseio.com",
  projectId: "jotter-8e6cd",
  storageBucket: "jotter-8e6cd.appspot.com",
  messagingSenderId: "140347387631",
  appId: "1:140347387631:web:0059c375ab82ff96c3351a",
});

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const FieldValue = firebase.firestore.FieldValue;

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = app.functions("europe-west1");
export const TaskEvent = firebase.storage.TaskEvent;

export const workspacesRef = db.collection("workspaces");
export const workspaceDatasRef = db.collection("workspaceData");
export const filesRef = db.collection("files");
