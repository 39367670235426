import { Box, Button, ButtonGroup, Flex } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ActiveWorkspaceContext } from "../../../providers/ActiveWorkspaceProvider";
import { Window } from "../../../ui/Window";
import { capitalize, CenteredSpinner } from "../../../utils/utils";
import { FileBrowser } from "../shared/FileBrowser";
import ActivityContentCard from "./ActivityContentCard";
import ActivitySettingsCard from "./ActivitySettingsCard";

const tabMap = {
  activity_settings: ActivitySettingsCard,
  content_and_tracking: ActivityContentCard,
  // advanced: ActivityAdvancedCard,
};

export default function ActivityEditor(props) {
  const {
    node: { id },
  } = props;

  const { fileTree } = useContext(ActiveWorkspaceContext);
  const [mode, setMode] = useState("activity_settings");

  // const { editorLockingIds } = useContext(EditorLockContext);
  // const hasUnsavedEditor = !!editorLockingIds.length;

  const node = fileTree[id];

  if (!node) return <CenteredSpinner />;

  const TabContent = tabMap[mode];

  return (
    <>
      <Window className="ActivityEditor" flexDirection="row">
        <Box
          flexGrow="0.3"
          flexShrink="0"
          pr="1.25rem"
          maxWidth="20rem"
          backgroundColor="rgba(127,127,127, 0.05)"
        >
          {/* <Heading
          mb="1.25rem"
          p="1.5rem 0 0 1.25rem"
          fontSize="1.5rem"
          fontWeight="600"
          id="Workspace_settings"
        >
          Content:
        </Heading> */}

          <FileBrowser nodeId={id} />
        </Box>

        {/* <Divider orientation="vertical" /> */}

        <Flex overflow="auto" direction="column" flexGrow="1" flexShrink="1">
          <ButtonGroup
            variant="ghost"
            colorScheme="blue"
            spacing={0}
            p="1.25rem"
            mb="1rem"
          >
            {Object.keys(tabMap).map((tabName) => (
              <Button
                key={tabName}
                isActive={tabName === mode}
                // isDisabled={hasUnsavedEditor}
                onClick={() => setMode(tabName)}
              >
                {capitalize(tabName)}
              </Button>
            ))}
          </ButtonGroup>

          <TabContent node={node} />
        </Flex>
      </Window>
    </>
  );
}
