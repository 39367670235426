import { CheckCircleIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Menu, Portal, Text } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { NodeMenuList } from "./files/PathCrumbs";
import { ActiveWorkspaceContext } from "./providers/ActiveWorkspaceProvider";
import { ContextMenuContext } from "./providers/ContextMenuProvider";
import { usePaperColor } from "./utils/appColors";
import { IconLookup } from "./utils/resourceUtils";
import { Card, Center, randomId } from "./utils/utils";

export const ImageNodeCardButton = React.forwardRef((props, ref) => {
  const {
    as = "button",
    name,
    thumbnailId,
    isDragTargeted,
    thumbSize = 256,
    width,
    // opacity,
    selected,
    ...rest
  } = props;
  const { workspaceId } = useParams();

  // const storage = useStorage();

  // const storageRef = useMemo(
  //   () =>
  //     storage.ref(
  //       [
  //         "/workspaces",
  //         workspaceId,
  //         "files",
  //         getThumbName(thumbnailId, thumbSize),
  //       ].join("/")
  //     ),
  //   [thumbnailId, storage, workspaceId, thumbSize]
  // );

  const { fileTree } = useContext(ActiveWorkspaceContext);
  const { aspectRatio } = fileTree[thumbnailId];

  // const downloadURL = useStorageDownloadURL(storageRef);

  const downloadURL = `https://firebasestorage.googleapis.com/v0/b/jotter-8e6cd.appspot.com/o/workspaces%2F${workspaceId}%2Ffiles%2F${thumbnailId}%40thumb%3A${thumbSize}?alt=media`;

  const bgColor = usePaperColor();

  return (
    <Card
      interactive
      selected={selected}
      as={as}
      ref={ref}
      width="100%"
      backgroundColor={selected ? "rgba(127, 100, 255, 0.2)" : bgColor}
      overflow="hidden"
      textOverflow="ellipsis"
      // opacity={hasLoaded ? opacity || 1 : 0}
      // aria-hidden={!hasLoaded}
      textAlign="left"
      minH={aspectRatio ? width / aspectRatio : width / 1.618}
      {...rest}
    >
      {selected && (
        <CheckCircleIcon
          fontSize="3rem"
          opacity={0.6}
          p="0.5rem"
          position="absolute"
          color="blue.200"
          zIndex="1"
        />
      )}

      {aspectRatio ? (
        <Image
          filter={
            selected
              ? "grayscale(50%) contrast(0.6) brightness(0.7)"
              : undefined
          }
          transition="opacity 250ms, filter 200ms"
          loading="lazy"
          src={downloadURL}
          width="100%"
          height={width / aspectRatio}
          // objectFit="cover"
        />
      ) : (
        <Center flexGrow={1}>
          <span>
            <span className="color-globe" /> Processing...
          </span>
        </Center>
      )}

      <Flex
        p="0.5rem"
        // backgroundColor={selected ? "rgba(127, 100, 255, 0.2)" : undefined}
      >
        <Text
          flexGrow="1"
          transition="opacity 250ms"
          opacity={0.6}
          fontSize="sm"
          _groupHover={{ opacity: 1 }}
          _groupFocus={{ opacity: 1 }}
        >
          {name}
        </Text>
      </Flex>
    </Card>
  );
});

const BaseItemButton = React.forwardRef((props, ref) => {
  const {
    isDragTargeted,
    icon,
    title,
    name,
    colorScheme,

    selected,
    className,
    ...rest
  } = props;

  const classNames = ["BaseItemButton", className].filter((v) => v);

  return (
    <Button
      className={classNames.join(" ")}
      ref={ref}
      // isFullWidth
      flexGrow="1"
      variant={isDragTargeted ? "solid" : "ghost"}
      display="flex"
      justifyContent="flex-start"
      textAlign="left"
      colorScheme={isDragTargeted ? "blue" : colorScheme}
      backgroundColor={selected ? "rgba(127, 100, 255, 0.2)" : undefined}
      borderRadius={selected ? 0 : "0.5rem"}
      overflow="hidden"
      title={title || name}
      {...rest}
    >
      {/* {item.node?.contentType?.startsWith("image/") ? (
        <ImageIcon node={item.node} />
        ) : ( */}
      <IconLookup
        boxSize={8}
        mr="1rem"
        flexShrink="0"
        icon={icon}
        defaultColor="gray.500"
        width="1.5rem"
        // _groupHover={{ display: "none" }}
      />

      <Box
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        flexGrow="1"
      >
        {name}
      </Box>

      {selected && <CheckIcon color="blue.500" />}
    </Button>
  );
});

function handleArrowKeyFocus(event, index) {
  const hasFocus = event.target === document.activeElement;
  if (!hasFocus) return;
  const isNext = ["ArrowDown", "ArrowRight"].includes(event.key);
  const isPrev = ["ArrowUp", "ArrowLeft"].includes(event.key);

  if (isPrev && index === 0) {
    document.getElementById("app-search-input")?.focus();
  } else if (isNext || isPrev) {
    const toBeFocused = document.body.querySelector(
      `[data-item-button-index="${index + (isNext ? 1 : -1)}"]`
    );

    if (toBeFocused) {
      event.preventDefault();
      toBeFocused.focus();
    }
  }
}

export const ItemButton = React.forwardRef((props, ref) => {
  const { index, isDragged, thumbnailId, node, ...rest } = props;

  const sharedProps = {
    ref,
    "data-item-button-index": index,
    onKeyDown: (event) => handleArrowKeyFocus(event, index),

    opacity: isDragged ? 0.1 : 1,
    border: isDragged ? "2px dashed lightblue" : undefined,
  };

  return (
    <ItemContextMenu node={node}>
      {thumbnailId ? (
        <ImageNodeCardButton
          thumbnailId={thumbnailId}
          {...sharedProps}
          {...rest}
        />
      ) : (
        <BaseItemButton {...sharedProps} {...rest} />
      )}
    </ItemContextMenu>
  );
});

function useContextMenu() {
  const { setContextMenu, contextMenu } = useContext(ContextMenuContext);

  const id = useMemo(() => randomId(), []);

  return useMemo(() => {
    function ContextMenuDynamic(props) {
      const { children, ...rest } = props;

      if (id !== contextMenu?.id) return null;

      return (
        <Portal>
          <Box
            // ref={(element) => (menuRef.current = element)}
            position="absolute"
            top={contextMenu.pageY + "px"}
            left={contextMenu.pageX + "px"}
            {...rest}
          >
            {children}
          </Box>
        </Portal>
      );
    }

    function onContextMenu(event) {
      const { pageX, pageY } = event;
      event.preventDefault();
      setContextMenu({ id, pageX, pageY });
    }

    return [ContextMenuDynamic, onContextMenu];
  }, [contextMenu, setContextMenu, id]);
}

function ItemContextMenu(props) {
  const { node, children } = props;

  const { selectedNodeIds } = useContext(ActiveWorkspaceContext);

  const [ContextMenu, onContextMenu] = useContextMenu();

  if (selectedNodeIds) return children; // TODO: show actions for the whole group instead

  return (
    <Box display="flex" flexDirection="column" onContextMenu={onContextMenu}>
      {children}

      <ContextMenu>
        <Menu isOpen>
          <NodeMenuList node={node} />
        </Menu>
      </ContextMenu>
    </Box>
  );
}

// function ImageIcon(props) {
//   const { node } = props;
//   const { workspaceId } = useParams();
//   const storage = useStorage();

//   const [url, setUrl] = useState(undefined);

//   const storageRef = useMemo(
//     () =>
//       storage.ref(
//         ["/workspaces", workspaceId, "files", getThumbName(node.id, 64)].join(
//           "/"
//         )
//       ),
//     [node, storage, workspaceId]
//   );

//   useEffect(() => {
//     let mounted = true;
//     async function fetchUrl() {
//       const url = await storageRef.getDownloadURL();
//       if (mounted) setUrl(url);
//     }

//     fetchUrl();
//     return () => (mounted = false);
//   }, [storageRef]);

//   return (
//     <Image
//       loading="lazy"
//       opacity={url ? 1 : 0}
//       transition="opacity 200ms"
//       // breaks rendering
//       // alt="Thumbnail image of the uploaded file"
//       height="2.4rem"
//       width="2.4rem"
//       flexShrink="0"
//       objectFit="cover"
//       objectPosition="center"
//       borderRadius="0.25rem"
//       mr="0.75rem"
//       // maxWidth="3rem"
//       // overflow="hidden"
//       src={url}
//     />
//   );
// }
