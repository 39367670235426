import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format as formatTimeAgo } from "timeago.js";
import { usePaperColor } from "./appColors";

export function capitalize(str = "", locale = navigator.language) {
  return humanize(
    str.substring(0, 1).toLocaleUpperCase(locale) + str.substring(1)
  );
}

export function humanize(str = "") {
  return str.replace?.(/_/g, " ");
}

export function randomId() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
}

const fadeOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export function throttle(callback, limit) {
  var waiting = false;

  return (...args) => {
    if (waiting) return;
    callback.apply(this, args);
    waiting = true;
    setTimeout(() => (waiting = false), limit);
  };
}

export function FadePresence(props) {
  const { children, ...rest } = props;

  return (
    <Box animation={"300ms linear forwards " + fadeOpacity} {...rest}>
      {children}
    </Box>
  );
}

export function contentTypeToIcon(contentType) {
  return ["audio", "video", "image"].find((type) =>
    contentType.startsWith(type + "/")
  );
}

export function getNodeIcon(node) {
  if (node.contentType) {
    return contentTypeToIcon(node.contentType);
  } else if (node.activitySettings?.enableSubActivities) {
    return node.activitySettings.numberOfSubActivitiesRequired
      ? "some_of"
      : "all_of";
  }
  return node.resourceType || node.type;
}

export function getNodeName(node) {
  return node.id === "$ROOT"
    ? "Files"
    : node.name || "Unnamed " + humanize(node.type);
}

export function getThumbName(fileName, size) {
  return fileName + "@thumb:" + size;
}

export function findPath(nodes, id) {
  let currentNode = nodes[id];
  if (!currentNode) throw new Error("Non-existent node id:", id);

  const path = [];

  // TODO: check this in development?
  // const visitedIds = {};

  while (currentNode) {
    // if (visitedIds[currentNode.id]) {
    //   console.log(path);
    //   throw new Error("Circular structure in nodes!");
    // }
    // visitedIds[currentNode.id] = true;
    path.unshift(currentNode);
    currentNode = currentNode.parent && nodes[currentNode.parent];
  }

  return path;
}

export function findAncestor(nodes, id, predicate) {
  return findPath(nodes, id).find(predicate);
}

export function useResourceStore(setValue) {
  const set = useCallback(
    (resource) => {
      const { type, id } = resource;

      // console.log("SET", resource);

      if (!type) throw new Error("Excepted a resource type");
      if (!id) throw new Error("Excepted a resource id");

      setValue((old) => ({
        ...old,
        [type]: { ...(old[type] || {}), [id]: resource },
      }));
    },
    [setValue]
  );

  const remove = useCallback(
    (resource) => {
      const { type, id } = resource;

      if (!type) throw new Error("Expected a resource type");
      if (!id) throw new Error("Expected a resource id");

      setValue((old) => {
        const resourcesOfType = { ...old[type] };
        delete resourcesOfType[id];
        return { ...old, [type]: resourcesOfType };
      });
    },
    [setValue]
  );

  return useMemo(() => [set, remove], [set, remove]);
}

// Updates a given key on an object as an effect using setter.
// Used to pass props "in reverse" up the tree, for example
// to provide searchers or file uploaders
export function useSetKeyValue(setter, id, value) {
  useEffect(() => {
    setter((old) => ({ ...old, [id]: value }));

    return () =>
      setter((old) => {
        const newResults = { ...old };
        delete newResults[id];
        return newResults;
      });
  }, [id, setter, value]);
}

export const colorModeIcons = { dark: SunIcon, light: MoonIcon };

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify({ value }));
}

export function hasInLocalStorage(key) {
  return localStorage.getItem(key) !== null;
}

export function readFromLocalStorage(key, defaultValue) {
  return hasInLocalStorage(key)
    ? JSON.parse(localStorage.getItem(key))?.value
    : defaultValue;
}

export function createStateProvider(defaultValue) {
  const ValueContext = createContext();
  const SetterContext = createContext();

  function Provider(props) {
    const { children, storageKey } = props;

    const initialState =
      storageKey && hasInLocalStorage(storageKey)
        ? readFromLocalStorage(storageKey)
        : defaultValue;

    const [value, setValue] = useState(initialState);

    useEffect(() => {
      if (storageKey) saveToLocalStorage(storageKey, value);
    }, [storageKey, value]);

    return (
      <SetterContext.Provider value={setValue}>
        <ValueContext.Provider value={value}>{children}</ValueContext.Provider>
      </SetterContext.Provider>
    );
  }

  function useValue() {
    return useContext(ValueContext);
  }

  function useSetter() {
    return useContext(SetterContext);
  }

  return [Provider, useValue, useSetter];
}

export function pluralize(count, stem, doCapitalize) {
  return (
    (count ? count + " " : doCapitalize ? "No " : "no ") +
    stem +
    (count === 1 ? "" : "s")
  );
}

export function timeAgo(date) {
  if (!date) return "never";
  if (date.toDate) date = date.toDate();

  return formatTimeAgo(date);
}

export function TimeAgo(props) {
  let { date } = props;

  if (date.toDate) date = date.toDate();

  return <span title={date?.toDateString()}>{timeAgo(date)}</span>;
}

export function useUiBlockingCallback() {
  const [isWorking, setIsWorking] = useState(false);

  const blockUi = useCallback(
    async (promise) => {
      if (isWorking)
        throw new Error("Already working. Should not have been called.");
      setIsWorking(true);
      await promise;
      setIsWorking(false);
    },
    [setIsWorking, isWorking]
  );

  return [isWorking, blockUi];
}

export function Nesting(props) {
  const { components, children } = props;

  return components.reduceRight(
    (children, Provider) => <Provider>{children}</Provider>,
    children
  );
}

export const Card = React.forwardRef((props, ref) => {
  const { selected, interactive, children, ...rest } = props;

  const bgColor = usePaperColor();

  return (
    <Flex
      ref={ref}
      flexDirection="column"
      boxShadow={selected ? "none" : "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"}
      _focus={{
        boxShadow: selected
          ? "0 0 0 4px rgba(127, 127, 255, 0.5)"
          : "0px 0px 20px 0px rgba(0, 0, 0, 0.3), 0 0 0 4px rgba(127, 127, 255, 0.5)",
      }}
      _hover={{
        // transform: `rotate(${rotation}deg) scale(1.1)`,
        boxShadow:
          interactive && !selected
            ? "0px 0px 20px 0px rgba(0, 0, 0, 0.3)"
            : undefined,
      }}
      _active={{
        // transform: `rotate(${rotation}deg) scale(1.1)`,
        boxShadow: "none",
      }}
      transform={selected ? "translateY(6px)" : undefined}
      backgroundColor={bgColor}
      borderRadius="0.25rem"
      transition="opacity 200ms, box-shadow 200ms, filter 200ms, transform 200ms"
      outline="none"
      {...rest}
    >
      {children}
    </Flex>
  );
});

export function Center(props) {
  const { children, ...rest } = props;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexGrow="1"
      alignSelf="stretch"
      {...rest}
    >
      {children}
    </Flex>
  );
}

export function CenteredSpinner() {
  return (
    <Center className="fade-in-slow" fontWeight="600" opacity="0.7">
      <Text fontSize="1.3rem">
        <span className="color-globe" /> Loading...
      </Text>
    </Center>
  );
}

export function FullScreenSpinner(props) {
  const { text = "Loading..." } = props;
  return (
    <Center
      className="fade-in-slow"
      height="100vh"
      fontWeight="600"
      // backgroundColor="rgba(127, 127,127, 0.1)"
    >
      <Flex flexDirection="column">
        <span className="color-bar" />
        <Text fontSize="1.3rem">{text}</Text>
      </Flex>
    </Center>
  );
}

export function CenteredMessage(props) {
  const { children } = props;

  return (
    <Center fontSize="1.2rem" fontWeight="600" {...props}>
      <Text maxW="60ch" m="1.25rem">
        {children}
      </Text>
    </Center>
  );
}

export function CenteredNotFoundMessage(props) {
  return (
    <CenteredMessage>
      File not found. If something existed at this address before, it may have
      been deleted. This message can also appear if you have connection trouble.
    </CenteredMessage>
  );
}

export function OfflineBarrier(props) {
  const { children } = props;

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const updateOnline = () => setIsOnline(window.navigator.onLine);

    window.addEventListener("online", updateOnline);
    window.addEventListener("offline", updateOnline);

    return () => {
      window.removeEventListener("online", updateOnline);
      window.removeEventListener("offline", updateOnline);
    };
  }, [setIsOnline]);

  if (!isOnline) {
    return <FullScreenSpinner text="No internet connection..." />;
  }

  return children;
}

export function ActionDialog(props) {
  const {
    title,
    children,
    actionButton,
    onClose,
    isWorking,
    onSubmit,
    ...rest
  } = props;

  const cancelRef = React.useRef();

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onClose={onClose} {...rest}>
      <AlertDialogOverlay
        style={{
          transition: "none",
          backdropFilter: "blur(2px)",
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="600">
            {title}
          </AlertDialogHeader>

          <form
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit?.(event);
            }}
          >
            <AlertDialogBody>{children}</AlertDialogBody>

            <AlertDialogFooter>
              <Button isDisabled={isWorking} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              {actionButton}
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function getWeek(date) {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
}

export function isLeapYear(date) {
  const year = date.getFullYear();
  if ((year & 3) !== 0) return false;
  return year % 100 !== 0 || year % 400 === 0;
}

// Get Day of Year
export function getDayOfYear(date) {
  const dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  const mn = date.getMonth();
  const dn = date.getDate();
  let dayOfYear = dayCount[mn] + dn;
  if (mn > 1 && isLeapYear(date)) dayOfYear++;
  return dayOfYear;
}

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const fullDayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
