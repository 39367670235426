import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { randomId } from "../utils/utils";

export const JobQueueContext = createContext();

export default function JobQueueProvider(props) {
  const { children } = props;

  const [jobObject, setJobObject] = useState({});

  const updateJob = useCallback((job) => {
    setJobObject((old) => {
      return {
        ...old,
        [job.key]: { ...old[job.key], ...job },
      };
    });
  }, []);

  const removeJob = useCallback((key) => {
    setJobObject((old) => {
      const jobs = { ...old };
      delete jobs[key];
      return jobs;
    });
  }, []);

  const jobs = useMemo(() => Object.values(jobObject), [jobObject]);

  const beginJob = useCallback(
    (job) => {
      job.callback(
        (progress, label) =>
          updateJob({ key: job.key, status: "active", label, progress }),
        (label) =>
          updateJob({
            key: job.key,
            progress: 1,
            label,
            status: "success",
            succeededTime: new Date(),
          }),
        (label) =>
          updateJob({
            key: job.key,
            label,
            status: "failure",
          })
      );
    },
    [updateJob]
  );

  const pushJob = useCallback(
    (label, callback) => {
      const key = randomId();
      const job = { key, label, callback, status: "waiting" };

      // console.log("Enqueue:", job);

      updateJob(job);
      return key;
    },
    [updateJob]
  );

  // console.log("Jobs:", jobs);

  useEffect(() => {
    const currentJob = jobs.find((job) => job.status === "active");
    const waitingJob = jobs.filter((job) => job.status === "waiting");

    if (currentJob || !waitingJob.length) return;

    beginJob(waitingJob[0]);
  }, [jobs, beginJob]);

  const currentJob = useMemo(
    () =>
      jobs.find((job) => job.status === "active") ||
      jobs.find((job) => job.status === "waiting"),
    [jobs]
  );
  const waitingJobs = useMemo(
    () => jobs.filter((job) => job.status === "waiting"),
    [jobs]
  );
  const finishedJobs = useMemo(
    () => jobs.filter((job) => job.status === "success"),
    [jobs]
  );
  const failedJobs = useMemo(
    () => jobs.filter((job) => job.status === "failure"),
    [jobs]
  );

  useEffect(() => {
    if (finishedJobs.length > 1) {
      finishedJobs.slice(0, -1).forEach((job) => removeJob(job.key));
    }
  }, [finishedJobs, removeJob]);

  const value = useMemo(
    () => ({
      pushJob,
      removeJob,
      jobs,
      currentJob,
      waitingJobs,
      failedJobs,
      finishedJobs,
    }),
    [
      pushJob,
      removeJob,
      jobs,
      currentJob,
      waitingJobs,
      failedJobs,
      finishedJobs,
    ]
  );

  return (
    <JobQueueContext.Provider value={value}>
      {children}
    </JobQueueContext.Provider>
  );
}
