import React, { createContext } from "react";
import { useFirestore, useUser, useFirestoreCollection } from "reactfire";

export const WorkspacesContext = createContext();

export function WorkspacesProvider(props) {
  const { children } = props;
  const db = useFirestore();
  const user = useUser();

  const workspaces = useFirestoreCollection(
    db.collection("workspaces").where("ownerUids", "array-contains", user.uid)
  );

  return (
    <WorkspacesContext.Provider value={workspaces}>
      {children}
    </WorkspacesContext.Provider>
  );
}
