import {
  Box,
  Button,
  ChakraProvider,
  CSSReset,
  Flex,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
  FirebaseAppProvider,
  SuspenseWithPerf,
  useAuth,
  useUser,
} from "reactfire";
import { Navbar } from "../navbar/Navbar";
import ActiveWorkspaceProvider from "../providers/ActiveWorkspaceProvider";
import ContextMenuProvider from "../providers/ContextMenuProvider";
import DeviceProvider from "../providers/DeviceProvider";
import DragAndDropStateProvider from "../providers/DragAndDropStateProvider";
import EditorLockProvider from "../providers/EditorLockProvider";
import JobQueueProvider from "../providers/JobQueueProvider";
import RecentInteractionProvider from "../providers/RecentInteractionProvider";
import { RedirectToWorkspaceOrCreate } from "../providers/RedirectToWorkspaceOrCreate";
import { WorkspacesProvider } from "../providers/WorkspacesProvider";
import { usePaperColor } from "../utils/appColors";
import { FullScreenSpinner, Nesting, OfflineBarrier } from "../utils/utils";
import { MainAreaRouter } from "./MainAreaRouter";
import { theme } from "./theme";

function AuthBarrier(props) {
  const { children } = props;

  const user = useUser();
  const auth = useAuth();

  const provider = new firebase.auth.GoogleAuthProvider();

  if (!user) {
    return (
      <Box m={5}>
        <Heading mb={5}>Welcome to DataCube</Heading>

        <Text mb={5}>DataCube is a personal planner and note-taking app.</Text>

        <Button
          onClick={() => auth.signInWithRedirect(provider)}
          colorScheme="blue"
        >
          Sign in with Google
        </Button>
      </Box>
    );
  }

  return children;
}

function AppLayout() {
  const { colorMode } = useColorMode();

  const paperColor = usePaperColor();

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", paperColor);
  }, [colorMode, paperColor]);

  return (
    <Flex
      className={["App fade-in-slow", colorMode].join(" ")}
      flexDirection="column"
      minHeight="100vh"
      // overflow="auto"
      // maxHeight="100vh"
      backgroundColor={{ dark: "#000", light: "#eee" }[colorMode]}
    >
      <Navbar />

      <MainAreaRouter />
    </Flex>
  );
}

function SuspenseProvider(props) {
  const { children } = props;

  return (
    <SuspenseWithPerf
      fallback={<FullScreenSpinner />}
      traceId={"suspense-root"}
    >
      {children}
    </SuspenseWithPerf>
  );
}

function App() {
  return (
    <FirebaseAppProvider
      firebaseConfig={{
        apiKey: "AIzaSyCPewPf_SGnX8gZFX1zDUVB6roE1TVx5BI",
        authDomain: "jotter-8e6cd.firebaseapp.com",
        databaseURL: "https://jotter-8e6cd.firebaseio.com",
        projectId: "jotter-8e6cd",
        storageBucket: "jotter-8e6cd.appspot.com",
        messagingSenderId: "140347387631",
        appId: "1:140347387631:web:0059c375ab82ff96c3351a",
      }}
    >
      <ChakraProvider theme={theme}>
        <CSSReset />
        <Nesting
          components={[
            DragAndDropStateProvider,

            JobQueueProvider,
            BrowserRouter,
            SuspenseProvider,
            RecentInteractionProvider,
            DeviceProvider,
            OfflineBarrier,
            AuthBarrier,
            WorkspacesProvider,
            ContextMenuProvider,
          ]}
        >
          <Route exact path="/">
            <RedirectToWorkspaceOrCreate />
          </Route>

          <Route path="/:workspaceId">
            <Nesting
              components={[
                ActiveWorkspaceProvider,
                EditorLockProvider,
                AppLayout,
              ]}
            />
          </Route>
        </Nesting>
      </ChakraProvider>
    </FirebaseAppProvider>
  );
}

export default App;
