import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  Center,
  readFromLocalStorage,
  saveToLocalStorage,
} from "../utils/utils";
import { FileViewer } from "./editors/FileViewer";

export function FilesPage() {
  const {
    path,
    url,
    params: { workspaceId },
  } = useRouteMatch();

  const rememberedNodeId =
    readFromLocalStorage("folder_" + workspaceId) || "$ROOT";

  const trimmedUrl = url.replace(/\/$/, "");

  return (
    <Switch>
      <Route path={path + "/:nodeId"}>
        <FileRouter />
      </Route>

      <Route exact path={path}>
        <Redirect to={trimmedUrl + "/" + rememberedNodeId} />
      </Route>

      <Route>
        <Center>
          <Text>TODO: 404</Text>
        </Center>
      </Route>
    </Switch>
  );
}

function FileRouter() {
  const {
    path,
    params: { workspaceId, nodeId },
  } = useRouteMatch();

  useEffect(() => {
    saveToLocalStorage("folder_" + workspaceId, nodeId);
  }, [workspaceId, nodeId]);

  return (
    <Switch>
      <Route exact path={path}>
        <FileViewer />
      </Route>

      <Route>
        <Center>
          <Text>TODO: 404</Text>
        </Center>
      </Route>
    </Switch>
  );
}
